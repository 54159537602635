import React, { useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { Button } from '@/shared/components/Button';
import { AuthContext } from '../../../../../../context/AuthContext';

const DataReactTable = () => {
  const [RejectData, setRejectData] = useState([]);
  const { apiGlobal } = useContext(AuthContext);

   // Pagination state
      const [currentPage, setCurrentPage] = useState(1);
      const itemsPerPage = 7;

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle empty values
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const fetchRejectData = async () => {
    try {
      const url = await fetch(`${apiGlobal}account_reject_list.php`);
      const data = await url.json();
      if (data.status && data.message === 'Success') {
        setRejectData(data.data);
      } else {
        setRejectData([]);
        console.error('Something Went Wrong While fetching Payment Reject list');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRejectData();
  }, []);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination Logic - Slice data for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = RejectData.slice(indexOfFirstItem, indexOfLastItem);

  // Total pages
  const totalPages = Math.ceil(RejectData.length / itemsPerPage);



  const DownloadPdf = async () => {
    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/fpdf/fpdf-easytable-master/new_voucher.php');
      
      // Log the response status and type for debugging
      console.log('Response Status:', response.status);
      console.log('Response Type:', response.headers.get('Content-Type'));
  
      if (!response.ok) {
        throw new Error('Failed to fetch the PDF');
      }
  
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = 'voucher.pdf';
      a.click();
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the PDF:', error);
    }
  };
  
  return (
    <div>
      <Table
        responsive
        style={{
          backgroundColor: '#f9f9f9',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <thead
          className="bg-danger"
          style={{
            color: 'white',
            textAlign: 'center',
            position: 'sticky',
            top: 0, // Makes the header stick at the top
            zIndex: 1, // Ensures the header stays on top of the table rows
          }}
        >
          <tr>
            <th>#</th>
            <th>Payment Date</th>
            <th>Staff Type</th>
            <th>Staff Name</th>
            <th>Booking Amount</th>
            {/* <th>Info</th> */}
            <th>Reject Remark</th>
          </tr>
        </thead>
        <tbody>
          {RejectData.length === 0 ? (
            // Render this message if there is no data
            <tr>
              <td colSpan="7" style={{ textAlign: 'center', padding: '20px' }}>
                <h3>No Reject Payment Data Available</h3>
              </td>
            </tr>
          ) : (
            RejectData.map((booking, index) => (
              <tr
                key={booking.id}
                style={{
                  backgroundColor: index % 2 === 0 ? '#ffffff' : '#f2f2f2',
                  textAlign: 'center',
                }}
              >
                <td>{index + 1}</td>
                <td>{formatDate(booking.payment_date)}</td>
                <td>{booking.staff_type}</td>
                <td>{booking.staff_name}</td>
                <td>{booking.balance}</td>
                {/* <td>
                  <Button variant="outline-primary" title="info">
                    <span className="lnr lnr-eye" />
                  </Button>
                  <Button variant="outline-primary" title="info" onClick={DownloadPdf}>
                    pdf
                  </Button>
                </td> */}
                <td>
                  <span className="text-danger">
                    {booking.remark}
                  </span>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {/* Pagination Controls */}
      <div style={{
 position: 'fixed', 
        bottom: '20px', 
        right: '20px', 
        zIndex: 999, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
}}
      >
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default DataReactTable;
