import React, { useContext, useEffect, useState } from 'react';
import { 
  Col, 
  Container, 
  Row, 
  Form, 
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { RestaurantContext } from '../../../../../context/RestaurantContext';
import { ThemeContext } from '../../../../../context/ThemeContext';
import RejectedRequestCard from './RejectedRequestCard';

const RejectedRequest = () => {
  const [rejectedMeal, setRejectedMeal] = useState([]);
  const [filterMeal, setFilterMeal] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { refresh, setRefresh } = useContext(RestaurantContext);
  const { currentTheme } = useContext(ThemeContext);



  const fetchData = async () => {
    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/meal_reject_list.php');
      const data = await response.json();
  
      console.log('Data:', data);
  
      if (data.status && data.message === 'Success') {
        console.log('Data fetched:', data.data);
        setRejectedMeal(data.data);
        setFilterMeal(data.data);
      } else {
        console.log('No data fetched');
        setFilterMeal([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to load data');
    }
  };
  


  useEffect(() => {
    console.log('Refreshing data...');
    fetchData();
  }, [refresh]);
  
  

  if (rejectedMeal.length <= 0) {
    return (
      <div>
        No meal Found
      </div>
    );
  }
  
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query) {
      const filteredData = rejectedMeal
      .filter(agentdata => agentdata.name.toLowerCase().includes(query.toLowerCase())
        || agentdata.city.toLowerCase().includes(query.toLowerCase())
        || agentdata.state.toLowerCase().includes(query.toLowerCase())
        || agentdata.country.toLowerCase().includes(query.toLowerCase()));
        setFilterMeal(filteredData);
    } else {
      setFilterMeal(rejectedMeal);
    }
  };

  // const refreshHandler = () => {
  //   setRefresh(prev => !prev);
  //   toast.success('Refresh....');
  // };

  return (
    <Container>
      <Row>
        <Col md={12} />
      </Row>
      <Row>
        <div className="col-md-4 mb-4 d-flex align-items-center">
          <Form.Control
            type="text"
            placeholder="Search by Name"
            value={searchQuery}
            onChange={handleSearch}
            className="flex-grow-1"
            style={{
              backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
              color: currentTheme === 'Dark' ? 'white' : 'black',
            }}
          />
        </div>
      </Row>
      <Row>
        <Container>
          {filterMeal.length > 0 ? (
            filterMeal.map(resData => (
              <RejectedRequestCard
                key={resData.id}
                id={resData.id}
                mealName={resData.name}
                resName={resData.restaurant_name}
                remark={resData.reject_reason}
                photo={resData.photo}
                setRefresh={setRefresh}
              />
            ))
          ) : (
            <p style={{
              backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
              color: currentTheme === 'Dark' ? 'white' : 'black',
            }}
            >No Meal Found
            </p>
          )}
        </Container>
      </Row>
    </Container>
  );
};

export default RejectedRequest;
