import React, { useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@/shared/components/Button';
import PropTypes from 'prop-types';

const ConfirmationModal = ({ 
  show, 
  handleClose, 
  handleDelete, 
  message,
  header,
  buttonType,
  buttonText,
}) => {
 const a = 5;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      
    >
      <Modal.Header
        closeButton
      >
      
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant={buttonType} onClick={handleDelete}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
  buttonType: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  };

export default ConfirmationModal;
