import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import DataReactTable from './components/DataReactTable';


const PendingTable = () => (
  <Container>
    <Row>
      <Col md={12} />
    </Row>
    <Row>
      <DataReactTable />
    </Row>
  </Container>
  );

export default PendingTable;

