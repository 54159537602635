import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select'; 
import PendingCard from './PendingCard';
import Loading from '../../../../../shared/components/Loading';
import { AuthContext } from '../../../../../context/AuthContext';

const Pending = () => {
  const { apiGlobal } = useContext(AuthContext);
  const [bookingData, setBookingData] = useState({});
  const [filteredBookingData, setFilteredBookingData] = useState({});
  const [paxDetailsHandler, setPaxDetailsHandler] = useState([]);
  const [tripOption, setTripOption] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

    

    const fetchBookingData = async () => {
      try {
         const url = await fetch(`${apiGlobal}booking_data.php`);
         const response = await url.json();
         if (response.status && response.message) {
          setBookingData(response.data);
          console.log(response.data);
          setFilteredBookingData(response.data);
          const tripOptions = response.data.map(trip => ({
            value: trip.id,
            label: `${trip.name } (${trip.trip_uin})`, 
          }));
          setTripOption(tripOptions);
           setIsLoading(false);
         } else {
          setBookingData({});
          setIsLoading(false);
         }
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      fetchBookingData();
    }, [refresh]);

    const filterBookingHandler = (selectedOption) => {
      setSelectedTrip(selectedOption); // Update selected trip
    
      if (!selectedOption) {
        setFilteredBookingData(bookingData); // Reset filter when no trip is selected
        return;
      }
    
      // Filter booking data based on selected trip ID
      const filteredData = bookingData.filter(trip => trip.id === selectedOption.value);
      setFilteredBookingData(filteredData);
    };


    if (isLoading) {
      return <Loading />;
    }

  return (
    <div>
      <Select
        name="tripName"
        className="react-select w-25 mb-4"
        classNamePrefix="react-select"
        options={tripOption}
        isClearable
        value={selectedTrip}
        placeholder="Select a trip"
        onChange={filterBookingHandler}
      />


      <div>
        {filteredBookingData.length > 0 ? (
        filteredBookingData.map(booking => (
          <PendingCard 
            key={booking.id}
            bookingData={booking}
            paxDetailsHandler={paxDetailsHandler}
            setPaxDetailsHandler={setPaxDetailsHandler}
            setRefresh={setRefresh}
          />
        ))
      ) : (
        <p>No bookings available.</p>
      )}
      </div>
      
    </div>
    );
}; 


export default Pending;
