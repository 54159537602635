import React, {
    createContext, useState, useEffect, useMemo, 
   } from 'react';
   import PropTypes from 'prop-types';
   
   export const Mealcontext = createContext();
   
   const MealContextProvider = ({ children }) => {
     const [refresh, setRefresh] = useState(false);
     const [meals, setMeals] = useState([]); 
     const [isDetailsClick, setIsDetailsClick] = useState(false);
     const [resaurantDetailsId, setRestaurantDetailsId] = useState();
     const [click, setclick] = useState('searchSelect');
     const [activeKey, setActiveKey] = useState('1');
     const [selectedid, setSelectedId] = useState(0);
     const [allMealData, setAllMealData] = useState({
           mealName: '',
           mealPrice: '',
           foc: '',
           foc_on: '',
           mealoffered: '',
           mealFormat: '',
           vegNon: '',
           cuisines: '',
           mealtype: '',
           meal_desc: '',
           meal_img: '',
           start_date: '',
           end_date: '',
           markup: '',
     });


     const value = useMemo(() => ({ 
        refresh, 
        setRefresh, 
        meals,
        setMeals,
        setIsDetailsClick,
        isDetailsClick,
        resaurantDetailsId,
        setRestaurantDetailsId,
        click, 
        setclick,
        activeKey, 
        setActiveKey,
        allMealData,
        setAllMealData,
        selectedid, 
        setSelectedId,
     }), [
      refresh,
       meals,
        isDetailsClick,
         resaurantDetailsId,
          click,
           allMealData,
            selectedid,
            ]);
   
     return (
       <Mealcontext.Provider value={value}>
         {children}
       </Mealcontext.Provider>
     );
   };
   
   MealContextProvider.propTypes = {
     children: PropTypes.node.isRequired,
   };
   
   export default MealContextProvider;
