import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../../../context/AuthContext';

const BasicDetails = ({ basicAgent }) => {
  const { apiGlobal } = useContext(AuthContext);

  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <p>Agent Name:{basicAgent.name}</p>
        </div>
        <div className="col-md-4">
          <p>Email:{basicAgent.email}</p>
        </div>
        <div className="col-md-4">
          <img
            src={`${apiGlobal}images/agent/${basicAgent.logo}`}
            alt="Thumbnail"
            className="img-fluid"
            style={{ cursor: 'pointer' }}
          />
  
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-6">
          <p>Address Line 1 :{basicAgent.address1}</p>
        </div>
        <div className="col-md-6">
          <p>Address Line 2 :{basicAgent.address2}</p>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-6">
          <p>Area :{basicAgent.area}</p>
        </div>
        <div className="col-md-6">
          <p>City:{basicAgent.city}</p>
        </div>
        <div className="col-md-6">
          <p>State:{basicAgent.state}</p>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-4">
          <p>Country:{basicAgent.country}</p>
        </div>
        <div className="col-md-4">
          <p>Pincode:{basicAgent.pincode}</p>
        </div>
        <div className="col-md-4">
          <p>State:{basicAgent.state}</p>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-4">
          <p>UAN:{basicAgent.uan}</p>
        </div>
        <div className="col-md-4">
          <p>TAX City:{basicAgent.taxCity}</p>
        </div>
        <div className="col-md-4">
          <p>TIN:{basicAgent.tin}</p>
        </div>
      </div>
      <hr />
    </div>
    
    );
}; 

  BasicDetails.propTypes = {
    basicAgent: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      area: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      pincode: PropTypes.string,
      uan: PropTypes.string,
      logo: PropTypes.string,
      taxCity: PropTypes.string,
      tin: PropTypes.string,
    }).isRequired,
  };

export default BasicDetails;
