
import React, {
  useContext, useState, useEffect, useRef, 
 } from 'react';
 import PropTypes from 'prop-types';
 import { Field, Form } from 'react-final-form';
 import { Col } from 'react-bootstrap';
 import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
 import Select from 'react-select';
 import {
  FormButtonToolbar, FormContainer, FormGroup, FormGroupField, FormGroupLabel, FormHalfContainer, 
 } from '@/shared/components/form/FormElements';
 import DatePicker from 'react-datepicker';
 import renderRadioButtonField from '@/shared/components/form/RadioButton';
 import { Button, ButtonGroup } from '@/shared/components/Button';
 import PaxDetailsTable from './PaxDetailsTable'; 
 import { BookingContext } from '../../../../../../context/BookingContext';
 import { AuthContext } from '../../../../../../context/AuthContext';

 
 const WizardFormOne = ({
 onSubmit, initialValues, date, setDate, 
}) => {
   const {
 setShowAddTripModal, tripDataFlag, setTripDataFlag, setPaxs, paxs, 
} = useContext(BookingContext);
   const { agentId, apiGlobal } = useContext(AuthContext); 
   const [selectedTrip, setSelectedTrip] = useState([]);
   const [tripOption, setTripOption] = useState([]);
   const [tripData, setTripData] = useState({});
   
   const [reInitialValue, setReInitialValue] = useState(initialValues || { paxDetails: [] });
    
   useEffect(() => {
     if (initialValues) {
       setReInitialValue(initialValues);
     }
   }, [initialValues]);
 
   const fetchTripOption = async () => {
     try {
       const url = await fetch(`${apiGlobal}/agent/trip_data.php?agent_id=${agentId}`);
       const response = await url.json();
       if (response.status && response.message === 'Success') {
         const tripOptions = response.data.map(item => ({
           value: item.id, 
           label: item.name,
         }));
         setTripOption(tripOptions);
       }
     } catch (error) {
       console.log(error);
     }
   };
 

   useEffect(() => {
    if (tripDataFlag.addMealINBooking === false) {
      fetchTripOption();
    }
  }, []);

   
 
 
   const fetchTripData = async (e, form) => {
    setPaxs([]);
     setSelectedTrip(e);
     try {
       let url;
       if (tripDataFlag.addMealINBooking) {
         url = await fetch(`${apiGlobal}/agent/trip_data_fetch.php?id=${tripDataFlag.tripID}`);
       } else {
         url = await fetch(`${apiGlobal}/agent/trip_data_fetch.php?id=${e.value}`);
       }
       
       const response = await url.json();
       console.log(response);
       if (response.status && response.message === 'Success') {
         const tripDatas = response.data[0];
        
         // Parse dates as Date objects
         const parsedStartDate = new Date(tripDatas.start_date); 
         const parsedEndDate = new Date(tripDatas.end_date);
 
         console.log(parsedStartDate); // Valid Date object
         console.log(parsedEndDate); // Valid Date object
 
         // // Update state with parsed dates
         setDate({
          startDate: parsedStartDate,
          endDate: parsedEndDate,
         });
 
         // Update pax details and other fields in the state
         setReInitialValue(prevState => ({
          ...prevState,
          paxDetails: response.pax_details || [],
          tripId: tripDatas.trip_uin,
          triptype: tripDatas.trip_type,
          startDates: parsedStartDate,
          endDate: parsedEndDate,
          selectedTrip: tripDataFlag.addMealINBooking ? tripDataFlag.tripID : e, // ✅ Corrected syntax
        }));
        
 
         // Manually update the form fields using form.change
         form.change('tripId', tripDatas.trip_uin);
         form.change('triptype', tripDatas.trip_type);
       } else {
         setTripData({});
       }
     } catch (error) {
       console.log(error);
     }
   };
 
   useEffect(() => {
    if (tripDataFlag.addMealINBooking) {
      fetchTripData({ value: tripDataFlag.tripID });
      setTripOption([{
        value: tripDataFlag.tripID, label: tripDataFlag.name,
      }]);
      setSelectedTrip([
        {
          value: tripDataFlag.tripID, label: tripDataFlag.name,
        },
      ]);
    }
  }, [tripDataFlag]);

  const showTripModal = () => {
    setShowAddTripModal(true);
  };
 
   return (
     <div
       className="container"
     >
       <div className="row justify-content-center">
         <div className="col-12 col-md-10 col-lg-8">
           <Card className="p-4 rounded-3">
             <Card.Body>
               <div className="mb-3 text-danger fw-bold">
                 <p>* Marked fields are required.</p>
               </div>
               <Form onSubmit={onSubmit} initialValues={reInitialValue}>
                 {({ handleSubmit, form }) => (
                   <form onSubmit={handleSubmit} className="w-100">
                     <div className="row">
                       {/* Select Trip */}
                       <div className="col-12 mb-3">
                         <label htmlFor="tripSelect" className="fw-bold">Select Trip</label>
                         <Field name="selectedTrip">
                           {({ input }) => (
                             <Select
                               {...input}
                               name="seletedTrip"
                               className="react-select w-100"
                               options={tripOption}
                               isClearable={false}
                               value={initialValues.selectedTrip || selectedTrip}
                               placeholder="Select a trip"
                               onChange={e => fetchTripData(e, form)}
                               required
                             />
                          )}
                         </Field>
                         {!tripDataFlag.addMealINBooking && (
                         <Button 
                           variant="outline-success" 
                           className="mt-2"
                           onClick={showTripModal}
                         >
                           Add Trip <span className="lnr lnr-plus-circle" />
                         </Button>
                        )}
                       </div>

                       {/* Trip ID */}
                       <div className="col-12 col-md-6 mb-3">
                         <label htmlFor="tripId" className="fw-bold">Trip ID</label>
                         <Field
                           name="tripId"
                           component="input"
                           type="text"
                           className="form-control"
                           placeholder="Trip ID"
                           readOnly
                         />
                       </div>
                       <br />
                       {/* Start Date & End Date */}
                       <div className="col-12 col-md-6 mb-3">
                         <label 
                           htmlFor="startDate" 
                           className="fw-bold"
                         >Start Date <span className="text-danger">*</span>
                         </label>
                         <Field name="startDates">
                           {({ input }) => (
                             <DatePicker
                               {...input}
                               className="form-control"
                               selected={date.startDate}
                               onChange={dates => setDate({
                                ...date,
                                startDate: dates,
                               })}
                               dateFormat="dd-MM-yyyy"
                               readOnly
                             />
                          )}
                         </Field>
                       </div>

                       <div className="col-12 col-md-6 mb-3">
                         <label 
                           htmlFor="EndDate" 
                           className="fw-bold"
                         >End Date <span className="text-danger">*</span>
                         </label>
                         <Field name="endDate">
                           {({ input }) => (
                             <DatePicker
                               {...input}
                               className="form-control"
                               selected={date.endDate}
                               onChange={dates => setDate({
                                ...date,
                                endDate: dates,
                               })}
                               dateFormat="dd-MM-yyyy"
                               readOnly
                             />
                          )}
                         </Field>
                       </div>

                       {/* Trip Type */}
                       <div className="col-12 mb-3">
                         <label htmlFor="triptype" className="fw-bold">Trip Type</label>
                         <div className="d-flex gap-3">
                           <Field name="triptype" component="input" type="radio" value="1" disabled />
                           <span>Group</span>
                           <Field name="triptype" component="input" type="radio" value="2" disabled />
                           <span>Family</span>
                         </div>
                       </div>

                       
                       <div className="col-12 d-flex justify-content-between mt-4">
                        
                         <Button 
                           variant="primary" 
                           type="submit" 
                           className="next"
                           style={{
                            float: 'right',
                           }}
                         >
                           Next
                                     
                         </Button>
                       </div>
                       
                     </div>
                   </form>
                )}
               </Form>
             </Card.Body>
           </Card>
         </div>
       </div>
     </div>
   );
 };
 
 WizardFormOne.propTypes = {
   onSubmit: PropTypes.func.isRequired,
   date: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
   }).isRequired,
   setDate: PropTypes.func.isRequired,
   previousPage: PropTypes.func.isRequired,
   initialValues: PropTypes.shape({
    selectedTrip: PropTypes.string.isRequired,
   }).isRequired,
 };
 
 export default WizardFormOne;



