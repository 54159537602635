
 import React, { useEffect, useState, useContext } from 'react';
 import PropTypes from 'prop-types';
 import { Field, Form } from 'react-final-form';
 import Select from 'react-select';
 import DatePicker from 'react-datepicker';
 import { FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements';
 import { Button } from '@/shared/components/Button';
 import { WizardButtonToolbar, WizardFormContainer, WizardTitle } from '@/shared/components/form/WizardFormElements';
 import { AuthContext } from '../../../../../../context/AuthContext';
 import { BookingContext } from '../../../../../../context/BookingContext';
 
 const WizardFormTwo = ({
 onSubmit, initialValues, previousPage, date, 
}) => {
   const [reinitialValue, setReInitialValue] = useState(initialValues);
   const { step1formData, setStep1formData } = useContext(BookingContext);
   const { apiGlobal } = useContext(AuthContext);
   const [LocationsNames, setLocationsNames] = useState([]);
   const [totalMeal, setTotalMeal] = useState(1);
   const [mealRate, setMealRate] = useState((parseInt(initialValues.price, 10) || 0) * totalMeal);

   console.log(reinitialValue);
 
   // Fetch location options on initial mount
   useEffect(() => {
     const fetchLocationOptions = async () => {
       try {
         const url = await fetch(`${apiGlobal}/restaurant_data_load.php?type=3&id=${reinitialValue.resid}`);
         const response = await url.json();
         console.log(response);
         if (response.status && response.message === 'Success') {
           setLocationsNames(
             response.data.map(item => ({
               value: item.id,
               label: item.restaurant_name,
             })),
           );
         }
       } catch (error) {
         console.error('Error fetching locations:', error);
       }
     };
 
     if (reinitialValue.resid) {
       fetchLocationOptions();
     }
   }, [reinitialValue.resid, apiGlobal]);
 
   // Update meal rate when totalMeal changes
   useEffect(() => {
     const price = parseInt(reinitialValue.price, 10) || 0;
     setMealRate(price * totalMeal);
   }, [totalMeal, reinitialValue.price]);
 
   const option = [
     { value: '1', label: 'Breakfast' },
     { value: '2', label: 'Snacks' },
     { value: '3', label: 'Lunch' },
     { value: '4', label: 'Dinner' },
   ];
 
   const restaurantLocationsOptions = initialValues?.locations?.map(location => ({
     value: location.id,
     label: location.restaurant_name,
   }));
 
   const mealOfferedArray = (initialValues.mealOffereds || '').split(',').map(item => item.trim());

   const filteredOptions = option.filter(item => mealOfferedArray.includes(String(item.value)));
 
   const dateonChangeHandler = (e) => {
     setStep1formData({
       ...step1formData,
       date: e.target.value,
     });
   };
 
   const handleonChange = (e, field) => {
     console.log(`Updating ${field} to ${e.target.value}`); // Debugging line
     setStep1formData(prevState => ({
       ...prevState,
       dayname: e.target.value,
     }));
   };
 
   console.log(step1formData);
   console.log(date.startDate);
   console.log(date.endDate);
 
   return (
     <Form onSubmit={onSubmit} initialValues={reinitialValue}>
       {({ handleSubmit, form }) => (
         <WizardFormContainer onSubmit={handleSubmit}>
           <WizardTitle>Meal Details</WizardTitle>
 
           <FormGroup>
             <FormGroupLabel>Meal Name</FormGroupLabel>
             <FormGroupField>
               <Field name="mealName" component="input" type="text" placeholder="Meal Name" readOnly />
             </FormGroupField>
           </FormGroup>
 
           <FormGroup>
             <FormGroupLabel>Restaurant Name</FormGroupLabel>
             <FormGroupField>
               <Field name="resName" component="input" type="text" placeholder="Restaurant Name" readOnly />
             </FormGroupField>
           </FormGroup>
 
           <FormGroup>
             <FormGroupLabel>Restaurant Location</FormGroupLabel>
             <FormGroupField>
               <Field
                 name="resLocation"
                 render={({ input, meta }) => (
                   <Select
                     {...input}
                     name="resLocation"
                     className="react-select w-100"
                     classNamePrefix="react-select"
                     options={restaurantLocationsOptions}
                     isClearable={false}
                     value={input.value || null} // Ensure the value is synced with form state
                     onChange={(selectedOption) => {
                       input.onChange(selectedOption); // Sync form state
                       setStep1formData(prevState => ({
                         ...prevState,
                         resLocation: selectedOption, // Sync local state
                       }));
                     }}
                   />
                 )}
               />
             </FormGroupField>
           </FormGroup>
 
           <FormGroup>
             <FormGroupLabel>Meal Date</FormGroupLabel>
             <FormGroupField>
               <Field
                 name="bookingDate"
                 className="w-100"
                 render={({ input }) => (
                   <DatePicker
                     {...input}
                     selected={input.value || null}
                     minDate={new Date(date.startDate)} 
                     maxDate={new Date(date.endDate)}   
                     onChange={(dates) => {
                       input.onChange(dates); 
                       dateonChangeHandler(dates); 
                     }}
                     dateFormat="dd-MM-yyyy"
                   />
                 )}
               />
             </FormGroupField>
           </FormGroup>
 
           <FormGroup>
             <FormGroupLabel>Day Name</FormGroupLabel>
             <FormGroupField>
               <Field
                 name="dayname"
                 component="input"
                 type="text"
                 placeholder="Eg. Day 1"
                 onChange={(e) => {
                   form.change('dayname', e.target.value); // Sync the form state with the value
                   setStep1formData(prevState => ({
                     ...prevState,
                     dayname: e.target.value, // Update the dayname in the state
                   }));
                 }}
               />
             </FormGroupField>
           </FormGroup>
 
           <FormGroup>
             <FormGroupLabel>Total Meals</FormGroupLabel>
             <FormGroupField>
               <Field
                 name="totalMeals"
                 render={({ input, meta }) => (
                   <input
                     {...input}
                     type="number"
                     placeholder="Total Meals"
                     value={step1formData.totalMeals} // Controlled value
                     onChange={(e) => {
                       const newTotalMeal = parseInt(e.target.value, 10) || 1; // Get the new total meal value
                       setTotalMeal(newTotalMeal); // Update the local state for total meals
 
                       const price = parseInt(reinitialValue.price, 10) || 0; // Get the meal price
                       const calculatedRate = price * newTotalMeal; // Calculate the new meal rate
 
                       // Update both totalMeals and mealRate in step1formData using setStep1formData
                       setStep1formData(prevState => ({
                         ...prevState,
                         totalMeals: newTotalMeal, // Update the totalMeals value
                         mealTotalRates: calculatedRate, // Update the meal rate
                       }));
 
                       input.onChange(e.target.value); // Ensure Form state sync with the updated input
                     }}
                   />
                 )}
               />
             </FormGroupField>
           </FormGroup>
 
           <FormGroup>
             <FormGroupLabel>Timing</FormGroupLabel>
             <FormGroupField>
               <Field
                 name="timing"
                 render={({ input, meta }) => (
                   <Select
                     {...input} // Pass down the field's input props (including value)
                     name="timing"
                     className="react-select w-100"
                     classNamePrefix="react-select"
                     options={filteredOptions}
                     isClearable={false}
                     value={input.value || null} // Ensure the value is synced with form state
                     onChange={(selectedOption) => {
                       input.onChange(selectedOption); // Sync form state
                       setStep1formData(prevState => ({
                         ...prevState,
                         timing: selectedOption, // Sync local state
                       }));
                     }}
                   />
                 )}
               />
             </FormGroupField>
           </FormGroup>
 
           <WizardButtonToolbar>
             {/* <Button variant="primary" type="button" disabled className="previous">
               Back
             </Button>
             <Button variant="primary" type="submit" className="next">
               Next
             </Button> */}
             <Button variant="secondary" type="button" onClick={previousPage}>
               Back
                                        
             </Button>
             <Button variant="primary" type="submit">
               Book                         
             </Button>
           </WizardButtonToolbar>
 
           <div
             style={{
               display: 'flex',
               justifyContent: 'flex-end',
               alignItems: 'center',
               padding: '10px 20px',
               backgroundColor: '#f8f9fa',
               borderRadius: '8px',
               boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
               marginTop: '20px',
             }}
           >
             <h1 style={{
               fontSize: '25px', color: '#28a745', fontWeight: 'bold', margin: 0,
             }}
             >
               AED {step1formData.mealTotalRates || '0'}
             </h1>
           </div>
         </WizardFormContainer>
       )}
     </Form>
   );
 };
 
 WizardFormTwo.propTypes = {
   onSubmit: PropTypes.func.isRequired,
   date: PropTypes.shape({
       startDate: PropTypes.string.isRequired,
       endDate: PropTypes.string.isRequired,
      }).isRequired,
   initialValues: PropTypes.shape({
     mealName: PropTypes.string.isRequired,
     resName: PropTypes.string.isRequired,
     price: PropTypes.string.isRequired,
     resid: PropTypes.string.isRequired,
     mealOffereds: PropTypes.string.isRequired,
     locations: PropTypes.arrayOf(
       PropTypes.shape({
         id: PropTypes.string.isRequired,
         restaurant_id: PropTypes.string.isRequired,
         restaurant_name: PropTypes.string.isRequired,
       }),
     ).isRequired,
   }).isRequired,
   previousPage: PropTypes.func.isRequired,
 };
 
 export default WizardFormTwo;
