import React, { 
  useState, 
  useEffect, 
  useContext, 
  useRef, 
} from 'react';
import { 
  Col, 
  Container, 
  Row, 
  Form,
 } from 'react-bootstrap';
import { Button, ButtonGroup } from '@/shared/components/Button';
import toast from 'react-hot-toast';
import { FaFilter } from 'react-icons/fa';
import MealCard from './MealCard';
import { Mealcontext } from '../../../../context/MealContext';
import { ThemeContext } from '../../../../context/ThemeContext';


const Search = () => {
  const { refresh, setRefresh } = useContext(Mealcontext);
  const { currentTheme } = useContext(ThemeContext);
  const [searchQuery, setSearchQuery] = useState(''); 
  const [mealsdata, setMealsData] = useState([]);
  const [filteredMealsData, setFilteredMealsData] = useState([]);
  const [mounted, setMounted] = useState(false);

  const isMounted = useRef(true);

  const fetchMealsData = async () => {
    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/meal_data_new.php');
      const mealsResponse = await response.json();

      if (mealsResponse && Array.isArray(mealsResponse.data)) {
        console.log('Loaded successfully');
        if (isMounted.current) {
          setMealsData(mealsResponse.data);
          setFilteredMealsData(mealsResponse.data);
          console.log(mealsResponse.data);
        }
      } else {
        console.log('No data found');
        if (isMounted.current) {
          setMealsData([]);
          setFilteredMealsData([]);
        }
      }
    } catch (error) {
      console.error('Error loading meals', error);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    fetchMealsData();
    return () => {
      isMounted.current = false;
    };
  }, [refresh]); 


  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query) {
      const filteredData = mealsdata.filter(meal => meal.name.toLowerCase().includes(query.toLowerCase()));
      setFilteredMealsData(filteredData); 
    } else {
      setFilteredMealsData(mealsdata); 
    }
  };

  // Refresh handler
  const refreshHandler = () => {
      // fetchMealsData(); 
      setRefresh(prev => !prev);
      toast.success('Refreshing...');
  };

  

  return (
    <Container>
      <Row>
        <Col md={12} />
      </Row>

      <Row>
        <div className="col-md-6 mb-4 d-flex align-items-center">
          <Form.Control
            type="text"
            placeholder="Search by Meal Name..."
            value={searchQuery}
            onChange={handleSearch}
            className="flex-grow-1"
            style={{
              backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
              color: currentTheme === 'Dark' ? 'white' : 'black',
            }}
          />
          <ButtonGroup icons dir="ltr" className="mt-2">
            <Button
              variant="outline-secondary"
              className="ms-3"
              title="Refresh"
              size="sm"
              onClick={refreshHandler}
            >
              <span className="lnr lnr-sync" />
            </Button>
          </ButtonGroup>

          <ButtonGroup icons dir="ltr" className="mt-2 mx-2">
            <Button 
              variant="outline-info"
              title="Filter" 
            >
              <FaFilter />
            </Button>
          </ButtonGroup>
        </div>
      </Row>

      <Row>
        <div>
          {filteredMealsData.length === 0 ? (
            <p>No meals found</p>
          ) : (
            filteredMealsData.map(meal => (
              <div key={meal.id}>
                <MealCard
                  id={meal.id}
                  mealname={meal.name}
                  restaurantname={meal.restaurant_name}
                  description={meal.description}
                  type={meal.meal_type}
                  capacity={meal.capacity}
                  price={meal.price}
                  finalprice={meal.final_price}
                  format={meal.meal_format}
                  image={meal.photo}
                  isVeg={meal.veg_non_veg}
                  mealOffereds={meal.meal_offered}
                  cuisine={meal.cuisines}
                  theme={currentTheme}
                  locations={meal.locations}
                />
              </div>
            ))
          )}
        </div>
      </Row>
    </Container>
  );
};

export default Search;
