import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { 
    Container, 
    Row, 
    Col, 
    Card, 
    Button, 
    Modal, 
    Form,
} from 'react-bootstrap';
import { PlusCircleFill, Trash, Eye } from 'react-bootstrap-icons';
import { ThemeContext } from '../../../../context/ThemeContext';

const AddAttachment = ({ uid }) => {
  const [images, setImages] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [preSelectedImages, setPreSelectedImages] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const { currentTheme } = useContext(ThemeContext);
 
  const imgPath = 'https://navrang.mvd-tech.io/API/images/meal/attachment/';

  const fetchSelectedImages = async () => {
      try {
         const url = await fetch(`https://navrang.mvd-tech.io/API/meal_data_fetch.php?id=${uid}`);
         const data = await url.json();
         if (data.status && data.message === 'Success') {
            const attachments = JSON.parse(data.data[0].attachment);
            setPreSelectedImages(attachments.map(attachment => ({
              id: attachment.logo_id,
              fileName: attachment.file_name,
              preview: `${imgPath}${attachment.file_name}`,
            })));
         }
      } catch (error) {
        console.error(error.message);
      }
  };

  useEffect(() => {
    fetchSelectedImages();
  }, [uid]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map(file => ({
      file,
      id: Math.random().toString(36).substring(7),
      preview: URL.createObjectURL(file),
      isNew: true,
    }));
    setImages(prev => [...prev, ...newImages]);
  };

  const deletePreSelectedImage = async (logoId) => {
    try {
      const formData = new FormData();
      formData.append('id', uid);
      formData.append('logo_id', logoId);

      const response = await fetch('https://navrang.mvd-tech.io/API/meal_attachment_delete.php', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      
      if (result.status) {
        toast.success('Image deleted successfully!');
        fetchSelectedImages();
      } else {
        alert(`Failed to delete image: ${result.message}`);
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      alert('Error deleting image. Please try again.');
    }
  };

  const initiateDelete = (image) => {
    setImageToDelete(image);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (imageToDelete) {
      await deletePreSelectedImage(imageToDelete.id);
    }
    setShowDeleteModal(false);
    setImageToDelete(null);
  };

  const removeImage = (id, isPreSelected, image) => {
    if (isPreSelected) {
      initiateDelete(image);
    } else {
      setImages((prev) => {
        const filtered = prev.filter(img => img.id !== id);
        const removedImage = prev.find(img => img.id === id);
        if (removedImage) {
          URL.revokeObjectURL(removedImage.preview);
        }
        return filtered;
      });
    }
  };
  

  const handlePreview = (image) => {
    setSelectedImage(image);
    setShowPreview(true);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('id', uid); 

    images.forEach((image) => {
        formData.append('attachment[]', image.file); 
    });

    try {
        const url = await fetch('https://navrang.mvd-tech.io/API/meal_attachment.php', {
            method: 'POST',
            body: formData,
        });

        const response = await url.json();
        
        if (response.status) {
            toast.success('Images uploaded successfully!');
            fetchSelectedImages();
            setImages([]);
        } else {
            alert(`Failed to upload images! ${response.message}`);
        }
    } catch (error) {
        console.error('Error:', error.message);
    }
  };

  React.useEffect(() => () => images.forEach(image => URL.revokeObjectURL(image.preview)), [images]);

  return (
    <Container className="py-4">
      {/* Preview Modal */}
      <Modal 
        show={showPreview} 
        onHide={() => setShowPreview(false)} 
        centered 
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && (
            <img
              src={selectedImage.preview}
              alt="Preview"
              style={{ width: '100%', height: 'auto' }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPreview(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
          setImageToDelete(null);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this image?</p>
          {imageToDelete && (
            <div className="text-center">
              <img
                src={imageToDelete.preview}
                alt="To be deleted"
                style={{ 
                  maxHeight: '200px',
                  maxWidth: '100%',
                  objectFit: 'contain',
                }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={() => {
              setShowDeleteModal(false);
              setImageToDelete(null);
            }}
          >
            Cancel
          </Button>
          <Button 
            variant="danger" 
            onClick={confirmDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Upload Area */}
      <Card
        className="mb-4"
        style={{
 backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
            color: currentTheme === 'Dark' ? 'white' : 'black', 
}}
      >
        <Card.Body>
          <Form.Group controlId="formFileMultiple" className="text-center">
            <Form.Label 
              style={{ 
                cursor: 'pointer',
                border: '2px dashed #dee2e6',
                borderRadius: '8px',
                padding: '2rem',
                width: '100%',
                marginBottom: '0',
                  backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
                  color: currentTheme === 'Dark' ? 'white' : 'black',
              }}
            >
              <PlusCircleFill size={40} className="mb-2 text-primary" />
              <p className="mb-0 text-muted">Click to upload images</p>
              <Form.Control 
                type="file" 
                multiple 
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />
            </Form.Label>
          </Form.Group>
        </Card.Body>
      </Card>

      {/* Pre-selected Images */}
      {preSelectedImages.length > 0 && (
        <>
          <h5 className="mb-3">Existing Images</h5>
          <Row xs={1} md={3} className="g-4 mb-4">
            {preSelectedImages.map(image => (
              <Col key={image.id}>
                <Card>
                  <div style={{ position: 'relative' }}>
                    <Card.Img
                      variant="top"
                      src={image.preview}
                      style={{ 
                        height: '200px',
                        objectFit: 'cover',
                      }}
                    />
                    <div 
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        display: 'flex',
                        gap: '0.5rem',
                      }}
                    >
                      <Button
                        variant="light"
                        size="sm"
                        onClick={() => handlePreview(image)}
                      >
                        <Eye />
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => removeImage(image.id, true, image)}
                      >
                        <Trash />
                      </Button>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}

      {/* New Images Preview Grid */}
      {images.length > 0 && (
        <>
          <h5 className="mb-3">New Images</h5>
          <Row xs={1} md={3} className="g-4">
            {images.map(image => (
              <Col key={image.id}>
                <Card>
                  <div style={{ position: 'relative' }}>
                    <Card.Img
                      variant="top"
                      src={image.preview}
                      style={{ 
                        height: '200px',
                        objectFit: 'cover',
                      }}
                    />
                    <div 
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        display: 'flex',
                        gap: '0.5rem',
                      }}
                    >
                      <Button
                        variant="light"
                        size="sm"
                        onClick={() => handlePreview(image)}
                      >
                        <Eye />
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => removeImage(image.id, false)}
                      >
                        <Trash />
                      </Button>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}

      {/* Image Count and Submit Button */}
      <div className="mt-4 d-flex justify-content-between align-items-center">
        <div className="text-muted">
          {images.length > 0 && (
            `${images.length} new ${images.length === 1 ? 'image' : 'images'} selected`
          )}
        </div>
        {images.length > 0 && (
          <Button 
            variant="primary" 
            onClick={handleSubmit}
          >
            Upload New Images
          </Button>
        )}
      </div>
    </Container>
  );
};

AddAttachment.propTypes = {
  uid: PropTypes.string.isRequired,
};

export default AddAttachment;
