import React, { useState, useContext } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Button, ButtonToolbar, ButtonGroup } from '@/shared/components/Button';
import ModalNew from '@/shared/components/Modal';
import EditBooking from './EditBooking';
import { AuthContext } from '../../../../../context/AuthContext';
import { BookingContext } from '../../../../../context/BookingContext';
import ConfirmationModal from '../ConfirmationModal';
// import Payment from '../../../Payment';






const PendingCard = ({
 bookingData, paxDetailsHandler, setPaxDetailsHandler, setRefresh, 
}) => {
       const [showEditModal, setShowEditModal] = useState(false);
       const [agentID, setAgentID] = useState();
       const [confirmBokingModal, setConfirmBookingModal] = useState(false);
       const [bookingID, setBookinigID] = useState();
       const [amount, setAmount] = useState(0);
       const [showPayment, setShowPayment] = useState(false);
       const { apiGlobal } = useContext(AuthContext);
       const { tripDataFlag, setTripDataFlag } = useContext(BookingContext);

       const history = useHistory();
       const editModalHandler = (agentid, bookingid) => {
        setAgentID(agentid);
        setBookinigID(bookingid);
        setShowEditModal(true);
       };

       const addMealHandler = (tripid, tripname) => {
        history.push('/agent/meal');
        setTripDataFlag({
          ...tripDataFlag,
          tripID: tripid,
          name: tripname,
          addMealINBooking: true,
        });
       };





   const handleEditTrip = async (value) => {
    console.log(value);
    const utcStartDate = value.startDates;
      const utcEndDate = value.endDate;

      const startDate = new Date(utcStartDate);
      const endDate = new Date(utcEndDate);
      const istOffset = 5.5 * 60 * 60 * 1000;
      const startDateIST = new Date(startDate.getTime() + istOffset);
     const endDateIST = new Date(endDate.getTime() + istOffset);  

     // Format the dates (DD-MM-YYYY)
     const formatDate = dates => `${dates.getFullYear()}-${String(dates.getMonth() + 1)
      .padStart(2, '0')}-${String(dates.getDate()).padStart(2, '0')}`;

      const formattedStartDate = formatDate(startDateIST);
      const formattedEndDate = formatDate(endDateIST);

      const formattedValues = {
        pax_details: paxDetailsHandler,
    };
    
    const formattedPaxDetails = JSON.stringify(formattedValues);
  



       const formData = new FormData();
       formData.append('trip_id', bookingID);
       formData.append('name', value.tripName);
       formData.append('start_date', formattedStartDate);
       formData.append('end_date', formattedEndDate);
       formData.append('trip_type', value.triptype);
      formData.append('pax_details', formattedPaxDetails);

    //    formData.forEach((values, key) => {
    //     console.log(`${key}: ${JSON.stringify(values)}`);
    // });

    
        

       try {
           const url = await fetch(`${apiGlobal}/agent/trip_edit.php`, {
            method: 'POST',
            body: formData,
           });
           const response = await url.json();
           if (response.status && response.message === 'Trip and Pax details updated successfully.') {
             toast.success('Trip Edit Successfull');
             setShowEditModal(false);
             setRefresh(prev => !prev);
           } else {
            toast.error('Error in updating trip details');
            setShowEditModal(true);
           }
           console.log(response);
       } catch (error) {
         console.log(error);
       }
   };
   const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle empty values
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const openConfirmmodal = (id) => {
    setBookinigID(id);
    setConfirmBookingModal(true);
  };

  const handleConfirmBooking = async () => {
    try {
      const url = await fetch(`${apiGlobal}/agent/booking_confirmation.php?id=${bookingID}&isApproved=1`);
      console.log(url);
      const response = await url.json();
      console.log(response);
      if (response.status && response.message === 'Success') {
        toast.success('Waiting for restaurant confirmation');
        setConfirmBookingModal(false);
        setRefresh(prev => !prev);
      } else {
        toast.error('Error in confirming booking');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const showpaymentModal = (amt) => {
    setShowPayment(true);
    setAmount(amt);
  };

  const getStatusMessage = (status) => {
    if (status === '1') return 'Awaiting approval from restaurant';
    if (status === '2') return 'Payment Pending';
    return 'Pending Approval';
  };
   


  return (

    <div>
      {!bookingData ? (
        <div>Booking Data Not Passed</div> // Show message if no bookingData
      ) : (
        <div className="card mb-4 shadow-sm" style={{ maxWidth: '100%', borderColor: 'black' }}>
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-md-12">
                <h5 className="fw-bold">{bookingData.name}</h5>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-4" style={{ display: 'flex' }}>
                <h6 className="fw-bold">Trip:<p className="">{bookingData.name}-{bookingData.trip_uin}</p></h6>
              </div>
              <div className="col-md-4" style={{ display: 'flex' }}>
                <p className="mb-1">
                  <strong>Start Date:</strong> {bookingData.start_date ? formatDate(bookingData.start_date) : ''}
                </p>
                <p style={{ marginLeft: '12px' }}>
                  <strong>End Date:</strong> {bookingData.end_date ? formatDate(bookingData.end_date) : ''}
                </p>
              </div>

              {/* <div className="col-md-4 text-end">
            
                <Button 
                  variant="outline-warning" 
                  size="sm" 
                  className="mx-1" 
                  title="Edit trip"
                  onClick={() => editModalHandler(bookingData.agent_id, bookingData.id)}
                >
                  Edit Trip
                </Button>
                <Button 
                  onClick={() => addMealHandler(bookingData.id, bookingData.name)} 
                  variant="outline-primary" 
                  size="sm" 
                  className="mx-1" 
                  title="Add Meal"
                >
                  Add Meal
                </Button>
              </div> */}
            </div>

            {bookingData.booking_details 
  && [...bookingData.booking_details]
    .sort((a, b) => (a.status === '0' ? -1 : 1)) // Sort meals with status '0' first
    .map((meal, index) => (
      <div className="card mb-4 shadow-sm" key={meal.id} style={{ maxWidth: '100%' }}>
        <div className="card-body">
          {/* Meal Day Header with Actions */}
          <div className="row mb-2 align-items-center" style={{ display: 'flex' }}>
            <div className="col-md-8 d-flex align-items-center">
              <h6 className="me-2">
                {meal.day_name} - {meal.booking_date ? formatDate(meal.booking_date) : ''}
                {' '} 
                <span className={String(meal.status) === '0' ? 'text-danger' : 'text-warning'}>
                  ({String(meal.status) === '0' ? 'Pending' : 'Awaiting Approval'})
                </span>
              </h6>
              {/* {
              meal.status === '2' && (
              <Button 
                variant="outline-success" 
                size="sm" 
                className="mx-2 mt-3" 
                title="Pay"
                onClick={() => showpaymentModal(meal.total_amount)}
              >
                Pay
              </Button>
)
            } */}
              
              
              {/* <Button 
                variant={String(meal.status) === '0' ? 'outline-danger' : 'outline-success'}
                size="sm"
                className="mx-2 mt-3"
                title="Approve"
                onClick={String(meal.status) === '0' && meal.id ? () => openConfirmmodal(meal.id) : undefined}
                disabled={String(meal.status) !== '0'} // Disable if status is not '0'
              >
                {String(meal.status) === '0' ? 'Approve' : 'Approved'}
              </Button> */}
            </div>
          </div>

          {/* Meal Details */}
          <div className="row border p-3 rounded">
            <div className="col-md-3">
              <p><strong>{meal.meal_name}/{meal.restaurant_name}</strong></p>
              <p className="text-muted">Locations: {meal.location}</p>
            </div>
            <div className="col-md-3">
              <p>
                <strong>Timing:</strong> 
                {{
                  1: 'Breakfast',
                  2: 'Snacks',
                  3: 'Lunch',
                  4: 'Dinner',
                }[meal.meal_offered] || 'Unknown'}
              </p>
              <p><strong>Total Meals:</strong> {meal.total_meal}</p>
            </div>
            <div className="col-md-3">
              <p><strong>Type:</strong> GIT, FIT, Delivery</p>
              <p><strong>Format:</strong> Thali</p>
            </div>
            <div className="col-md-3">
              <p><strong>Amount:</strong> AED {meal.total_amount}</p>
              <p>
                <strong>Status: </strong> 
                <span className="text-warning">
                  <span className="text-warning">{getStatusMessage(meal.status)}</span>

                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    ))}


            

          </div>
          <Modal
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            centered
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Trip</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EditBooking 
                onSubmit={handleEditTrip} 
                agentID={agentID} 
                bookingID={bookingID}
                setPaxDetailsHandler={setPaxDetailsHandler}
                paxDetailsHandler={paxDetailsHandler}
              /> 
              {/* <AddTrip onSubmit={handletripAdd} /> */}
            </Modal.Body>
          </Modal>
          <ConfirmationModal 
            show={confirmBokingModal}
            handleClose={() => setConfirmBookingModal(false)}
            handleDelete={handleConfirmBooking}
            header="Confirm Booking"
            message="Are you sure you want to confirm this Booking?"
            buttonType="success"
            buttonText="Confirm"
          />
         

          <Modal show={showPayment} onHide={() => setShowPayment(false)} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Make Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <Payment amount={amount} /> */}
            </Modal.Body>
          </Modal> 

        </div>

    
)}
    </div>
  );
}; 

PendingCard.propTypes = {
  bookingData: PropTypes.shape({
    id: PropTypes.string.isRequired, 
    agent_id: PropTypes.string.isRequired, 
    name: PropTypes.string.isRequired, 
    trip_uin: PropTypes.string.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
    booking_details: PropTypes.arrayOf(PropTypes.shape({


    })).isRequired,
  }).isRequired, 
  paxDetailsHandler: PropTypes.arrayOf(
    PropTypes.shape({
    }),
  ).isRequired,
  setPaxDetailsHandler: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
};



export default PendingCard;
