import React, { useContext, useEffect } from 'react';
import { 
  Route, 
  Switch, 
  Redirect,
  useHistory,
 } from 'react-router-dom'; // Import Redirect if needed
import MainWrapper from '../MainWrapper';
import LogIn from '../../Account/LogIn/index';
import WrappedRoutes from './WrappedRoutes';
import { AuthContext } from '../../../context/AuthContext';
import AppDashboard from '../../Dashboards/App';
import { PermissionContext } from '../../../context/PermissionContext';




const Router = () => {
  const { 
    isLoggedIn, 
    setIsLoggedIn, 
    apiGlobal,
  } = useContext(AuthContext); 

  const history = useHistory();
  useEffect(() => {
    const checkLoginStatus = async () => {
      try { 
        const response = await fetch(`${apiGlobal}/auth.php`);
        const data = await response.json();
        console.log(`isLogin:${data.loggedIn}`);
        setIsLoggedIn(data.loggedIn);
      } catch (error) {
        console.error('Error checking login status:', error);
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();
  }, []); 


  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route exact path="/" component={isLoggedIn ? AppDashboard : LogIn} />
          <Route path="/" component={isLoggedIn ? WrappedRoutes : LogIn} />
        </Switch>
      </main>
    </MainWrapper>
 );
};

export default Router;
