import React, { useState, useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { 
  FaLocationDot, 
  FaPercent, 
  FaUser, 
  FaPhone,
 } from 'react-icons/fa6'; 
import { MdAlternateEmail } from 'react-icons/md';
import { Button } from '@/shared/components/Button';
import EditAgent from '../EditAgent';
import { ThemeContext } from '../../../context/ThemeContext';






const RejectedRequestCard = ({
    id,
                name,
                address1,
                address2,
                area,
                city,
                state,
                country,
                remark,
                username,
                useremail,
                userphone,
                setRefresh,
}) => {
     const [showEditModel, setShowEditModel] = useState(false);
     const [agentdata, setAgentData] = useState([]);
     const [agentId, setAgentId] = useState(id);
     const [formValues, setFormValues] = useState({});
     const { currentTheme } = useContext(ThemeContext);


     const fetchAgentData = async () => {
      try {
        const url = await fetch(`https://navrang.mvd-tech.io/API/agent_data_fetch.php?id=${agentId}`);
        const data = await url.json();
        if (data.status && data.message === 'Success') {
          // console.log('Data fetched:', data.data);
          setAgentData(data.data[0]);
          setFormValues({
            agentName: data.data[0].name,
            addressLine1: data.data[0].address1,
            addressLine2: data.data[0].address2,
            area: data.data[0].area,
            state: data.data[0].state,
            pincode: data.data[0].pincode,
            city: data.data[0].city,
            country: data.data[0].country,
            logo: data.data[0].logo,
            gstProof: data.data[0].gst_proof_file,
            addressProof: data.data[0].address_proof_file,
            gstNO: data.data[0].gst_no,
            gstType: data.data[0].gst_type,
            uan: data.data[0].uan,
            location: data.data[0].location,
            defaultMarkup: data.data[0].markup,
            taxId: data.data[0].tax_id,
            taxCity: data.data[0].tax_city,
          });
        } else {
          console.log('No data fetched');
          setAgentData([]);
        }
      } catch (error) {
        console.error('Error fetching agent data', error);
      }
     };

     useEffect(() => {
      fetchAgentData();
     }, []);


     const onEditAgentHandler = async (values) => {
      const formData = new FormData();
      formData.append('id', agentId);
      formData.append('agentName', values.agentName);
      formData.append('addressLine1', values.addressLine1);
      formData.append('addressLine2', values.addressLine2);
      formData.append('area', values.area);
      formData.append('uan', values.uan);
      formData.append('city', values.city);
      formData.append('state', values.state);
      formData.append('country', values.country);
      formData.append('logo', values.file);
      formData.append('gst_no', values.gstNo);
      formData.append('gst_type', values.gstType);
      formData.append('gst_proof_file', values.gstProof);
      formData.append('pincode', values.pincode);
      formData.append('address_proof_file', values.addressProof);
      formData.append('defaultMarkup', values.defaultMarkup);
      formData.append('locationOnMap', values.location);
      formData.append('taxId', values.taxId);
      formData.append('taxCity', values.taxCity);
      formData.append('type', '1');
        
        try {
          const url = await fetch('https://navrang.mvd-tech.io/API/agent_edit.php', {
            method: 'POST',
            body: formData,
          });
          const data = await url.json();
          console.log('Data:', data);
          if (data.status && data.message === 'Record updated successfully.') {
              toast.success('Agent data updated successfully');
              setRefresh(prev => !prev);
              setShowEditModel(false);
          } else {
            toast.error('Failed to update agent data');
          }
        } catch (error) {
          console.error('Error updating agent data:', error);
          toast.error('Failed to update agent data');
        }
     };

  
  

  return (
    <div 
      className="card mb-3 position-relative" 
      style={{
        maxWidth: '100%',
        border: '2px solid',
        borderRadius: '12px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
        overflow: 'hidden',
        transition: 'transform 0.3s ease',
          backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
          color: currentTheme === 'Dark' ? 'white' : 'black',
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(1.02)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1)';
      }}
      
    >

      <div className="row g-0">
        <div className="col-md-12">
          <div className="remark mt-2">
            <i>
              Remark : <span style={{ color: 'red' }}>{remark}</span>
            </i> 
          </div>
          <hr />
        </div>
        <div className="col-md-4 col-12">
          <div className="card-body">
            <h3 className="card-title">
              Agent Name : {name}

            </h3>
            <p className="card-text"><FaLocationDot />
              {' '} {address1}, {address2}
            </p>
            <p className="card-text">
              {area}, {city}
              
            </p>
            <p className="text-muted"> {state}, {country}</p>
          </div>
        </div>

        {/* <div className="col-md-4 col-12 mt-4">
          <div className="card-body">
            <br />
            <p className="card-title"><FaPercent />{' '}TIN #: N/A</p>
            <p className="card-text"><FaPercent />{' '}Tax City: N/A</p>
            <p className="card-text">
              <FaPercent /> {' '}Default Markup: 0%
            </p>
          </div>
        </div> */}

        <div className="col-md-4 col-12">
          <div className="card-body">
            <h5 className="card-title">Primary Contact
              
            </h5>
            <p className="card-text"><FaUser />{' '}Name: {username}</p>
            <p className="card-text"><MdAlternateEmail />{' '}Email: {useremail}</p>
            <p className="card-text"><FaPhone />{' '}Phone: {userphone}</p>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="card-body">
            <div className="col-md-12" />
          </div>
        </div>
       
      </div>
      <Button 
        variant="outline-danger"
        title="Edit Info"
        className="w-[12%] align-self-end"
        size="sm"
        style={{
          marginRight: '10px',
        }}
        onClick={() => setShowEditModel(true)}
        
      >
        Edit Agent 
      </Button>    

      {/* <DeleteConfirmationModal
        show={deleteModalShow}
        handleClose={handleDeleteModalClose}
        handleDelete={() => onDeleteHandler(id)}
        message="Are you sure you want to delete this Agent?"
      />
      <DeleteConfirmationModal
        show={contactdeleteModalShow}
        handleClose={handlecontactDeleteModalClose}
        handleDelete={() => onDeletecontact(id)}
        message="Are you sure you want to delete this Contact details?"
      /> */}
      
      <Modal show={showEditModel} onHide={() => setShowEditModel(false)} size="xl">
        <Modal.Header 
          style={{
         backgroundColor: currentTheme === 'Dark' && '#232329',
         color: currentTheme === 'Dark' && '#fff',
       }}
          closeButton
          closeVariant={currentTheme === 'Dark' && 'white'}
          
        >
          
          <Modal.Title>Edit Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}
        >
          <EditAgent 
            formValues={formValues}
            onSubmit={onEditAgentHandler}
          />
        </Modal.Body>
      </Modal>

      {/* <Modal show={showModalcontact} onHide={() => setShowModalcontact(false)} size="lg">
        <Modal.Header
          style={{
          backgroundColor: theme === 'Dark' && '#232329',
          color: theme === 'Dark' && '#fff',
        }}
          closeButton
          closeVariant={theme === 'Dark' && 'white'}
        >
          <Modal.Title>Additional Information
            <Button variant="outline-primary" className="mx-4" onClick={onPasswordBtn}>Change Password</Button>
            
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
          backgroundColor: theme === 'Dark' && '#232329',
        }}
        >
          <AddContactInfo
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onEditContactSubmit}
          />
        </Modal.Body>
      </Modal> */}

      {/* <Modal show={showModalpassword} onHide={handleClosepassword} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangedPassword
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onAddPasswordHander}
          />
        </Modal.Body>
      </Modal> */}

    </div>
  );
}; 

RejectedRequestCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  area: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  remark: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  useremail: PropTypes.string.isRequired,
  userphone: PropTypes.string.isRequired,
  setRefresh: PropTypes.func.isRequired,

};



export default RejectedRequestCard;
