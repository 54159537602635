import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'react-bootstrap';

const BookingInfo = ({ bookingData, paxs }) => {
    const [showPaxTable, setShowPaxTable] = useState(false);

    // Toggle the visibility of the pax details table
    const togglePaxTable = () => {
        setShowPaxTable(prevState => !prevState);
    };
    const formatDate = (dateString) => {
        if (!dateString) return ''; // Handle empty values
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    };
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            <p>Restaurant: {bookingData.restaurant_name}</p>
          </div>
          <div className="col-md-4">
            <p>Location: {bookingData.location}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-4">
            <p>Meal Name:{bookingData.meal_name}</p>
          </div>
          <div className="col-md-4">
            <p className="card-text">
              Timing : {
                (() => {
                  switch (bookingData.meal_offered) {
                    case '1':
                      return 'Breakfast';
                    case '2':
                      return 'Snacks';
                    case '3':
                      return 'Lunch';
                    case '4':
                      return 'Dinner';    
                    default:
                      return 'NAN';
                  }
                })()
              }
            </p>
          </div>
          <div className="col-md-4">
            <p>Meal Type:{bookingData.meal_type}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-6">
            <p>Booking Date:{formatDate(bookingData.mealDate)}</p>
          </div>
          <div className="col-md-6">
            <p>Tripname:{bookingData.trip_name}</p>
          </div>
        </div>
        <hr />
        
        {/* Toggle Button for Pax Details */}
        <div className="row">
          
          
          {/* Conditionally Render the Pax Table */}
          {showPaxTable && (
            <Table
              responsive
              bordered
              style={{
                marginTop: '12px',
            }}
            >
              <thead>
                <tr>
                  <th>Sr no</th>
                  <th>Name</th>
                  <th>Age Group</th>
                  <th>Mail Id</th>
                  <th>Phone No</th>
                </tr>
              </thead>
              <tbody>
                {paxs.flat().map((pax, index) => (
                  <tr key={pax.id}>
                    <td>{index + 1}</td>
                    <td>{pax.pax_name}</td>
                    <td>{pax.age_group === '1' ? 'Adult' : 'Child'}</td>
                    <td>{pax.email_id}</td>
                    <td>{pax.mobile_no}</td>              
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <div className="col-md-3">
            <Button onClick={togglePaxTable}>
              {showPaxTable ? 'Hide Pax Details' : 'Show Pax Details'}
            </Button>
          </div>
        </div>

        <hr />
      </div>
    );
};

BookingInfo.propTypes = {
    bookingData: PropTypes.shape({
        mealDate: PropTypes.string,
        meal_name: PropTypes.string,
        meal_type: PropTypes.string,
        meal_offered: PropTypes.string,
        day_name: PropTypes.string,
        meal_amount: PropTypes.string,
        total_meal: PropTypes.string,
        total_amount: PropTypes.string,
        restaurant_name: PropTypes.string,
        location: PropTypes.string,
        trip_name: PropTypes.string, 
    }).isRequired,
    paxs: PropTypes.arrayOf(
        PropTypes.arrayOf( // Updated to reflect nested array structure
            PropTypes.shape({
                pax_name: PropTypes.string.isRequired,
                age_group: PropTypes.string.isRequired,
                mobile_no: PropTypes.string.isRequired,
                email_id: PropTypes.string.isRequired,
            }),
        ).isRequired,
    ).isRequired,
};

export default BookingInfo;
