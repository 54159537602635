import React, { useEffect, useState, useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AgentContext } from '../../context/AgentContext';
import { AuthContext } from '../../context/AuthContext';
import RequestShort from './RequestShort';

const RequestList = () => {
  const [request, setRequest] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [errors, setError] = useState(null); 
  const { refresh, statusChanged } = useContext(AgentContext);
  const { apiGlobal } = useContext(AuthContext);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiGlobal}agent_notappro_list.php`);
      const data = await response.json();
      if (data.status && data.message === 'Success') {
        setRequest(data.data);
      } else {
        setRequest([]);
      }
    } catch (error) {
      setError('Failed to load data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [statusChanged, refresh]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>{errors}</div>;
  }
   

  return (
    <Container className="d-flex flex-wrap">
      <Row>
        <Col md={12} />
      </Row>      
      <Row>
        {request.length > 0 ? (
          request.map((req, index) => (
            <RequestShort 
              key={req.id} 
              index={index} 
              date={req.date} 
              name={req.name} 
              id={req.id} 
              logo={req.logo}             
            />
          ))
        ) : (
          <p>No requests available</p>
        )}
      </Row>
    </Container>
  );
};

export default RequestList;
