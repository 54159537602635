import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody,
} from '@/shared/components/Card';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import { renderMaskedField } from '@/shared/components/form/FormField';
import { Button } from '@/shared/components/Button';

const AddMarkupPercentageForm = ({ onSubmit }) => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <Form onSubmit={onSubmit} horizantal>
          {({ handleSubmit }) => (
            <FormContainer onSubmit={handleSubmit}>
              <FormGroup>
                <FormGroupLabel>
                  Add Markup Percentage
                </FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="markuppercentage"
                    component="input"
                    type="number"
                    placeholder="Enter Markup"
                    required
                  />
                </FormGroupField>
              </FormGroup>
              <FormButtonToolbar>
                <Button variant="success" type="submit">Add % And Confirm Meal</Button>
              </FormButtonToolbar>
            </FormContainer>
            )}
        </Form>
      </CardBody>
    </Card>
  </Col>
  );

  AddMarkupPercentageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AddMarkupPercentageForm;
