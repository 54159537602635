import React, { useContext, useEffect } from 'react';
import { Button } from '@/shared/components/Button';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { BookingContext } from '../../../../../../context/BookingContext';

// Styled-components for styling
const Container = styled.div`
  padding: 20px;
  background-color: #f7f7f7;
  min-height: 47vh;
`;

const PaxDetailsCardWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const TableWrapper = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-top: 20px;
`;

const ReactSelect = styled(Select)`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  font-size: 12px;
  &:hover {
    border-color: #007bff;
  }
`;

const InputField = styled.input`
  width: 100%;
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  font-size: 12px;
  margin-bottom: 10px;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const ButtonAdd = styled(Button)`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 10px;
  &:hover {
    background-color: #0056b3;
  }
`;

const PaxDetailsTable = ({ paxDetails }) => {
  const paxData = paxDetails || [];
  const { paxs, setPaxs } = useContext(BookingContext);

  // Function to extract ID from the pax name (e.g., "11-Bhumika")
  const extractIdFromName = (name) => {
    const match = name.match(/^(\d+)-/); // Regex to match digits before hyphen
    return match ? match[1] : null;
  };
  
  // Function to handle checkbox change (check or uncheck)
  const checkboxonChange = (e, pax) => {
    const paxId = extractIdFromName(e.target.name);
  
    if (e.target.checked) {
      // If checked, add pax to the paxs state if not already present
      setPaxs((prevPaxs) => {
        if (!prevPaxs.includes(paxId)) {
          return [...prevPaxs, paxId];
        }
        return prevPaxs;
      });
    } else {
      // If unchecked, remove pax from the paxs state
      setPaxs(prevPaxs => prevPaxs.filter(id => id !== paxId));
    }
  };
  
  // Logging updated paxs state using useEffect
  useEffect(() => {
    console.log(paxs); // This will log paxs state whenever it changes
  }, [paxs]);
  
  return (
    <Container>
      <PaxDetailsCardWrapper>
        {/* Table Section with Vertical Scroll */}
        <TableWrapper>
          <Table responsive hover>
            <thead>
              <tr>
                <th>✅</th>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone No.</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {paxData.length === 0 ? (
                <tr>
                  <td colSpan="6">No Pax details available</td>
                </tr>
              ) : (
                paxData.map((pax, index) => (
                  <tr key={pax.id}>
                    <td>
                      <input
                        name={`${pax.id}-${pax.pax_name}`}
                        type="checkbox"
                        onChange={e => checkboxonChange(e, pax)}
                        style={{ color: 'green', width: '15px' }}
                      />
                    </td>
                    <td>{index + 1}</td>
                    <td>{pax.pax_name}</td>
                    <td>{pax.email_id}</td>
                    <td>{pax.mobile_no}</td>
                    <td>{pax.age_group === '1' ? 'Adult' : 'Child'}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </TableWrapper>
      </PaxDetailsCardWrapper>
    </Container>
  );
};

PaxDetailsTable.propTypes = {
  paxDetails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      email_id: PropTypes.string.isRequired,
      pax_name: PropTypes.string.isRequired,
      mobile_no: PropTypes.string.isRequired,
      age_group: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default PaxDetailsTable;
