import React, { useState, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ThemeContext } from '../../../../context/ThemeContext';

const DeleteConfirmationModal = ({ 
  show, 
  handleClose, 
  handleDelete, 
  message,
}) => {
   const { currentTheme } = useContext(ThemeContext);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      
    >
      <Modal.Header
        closeButton
        closeVariant={currentTheme === 'Dark' && 'white'}
        style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}>
      
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}>
        {message}
      </Modal.Body>
      <Modal.Footer style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DeleteConfirmationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
  };

export default DeleteConfirmationModal;
