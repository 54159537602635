import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select'; 
import PastCard from './PastCard';
import { AuthContext } from '../../../../../context/AuthContext';

const Past = () => {
    const { apiGlobal } = useContext(AuthContext);
    const [bookingData, setBookingData] = useState({});
    const [filteredBookingData, setFilteredBookingData] = useState({});
    const [tripOption, setTripOption] = useState([]);
    const [selectedTrip, setSelectedTrip] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      const fetchPendingBookingData = async () => {
        try {
          const response = await fetch(`${apiGlobal}booking_past_list.php`);
          const data = await response.json();
          if (response.status && response.message) {
            setBookingData(response.data);
            setFilteredBookingData(response.data);
            const tripOptions = response.data.map(trip => ({
              value: trip.id,
              label: `${trip.name } (${trip.trip_uin})`, 
            }));
            setTripOption(tripOptions);
             setIsLoading(false);
           } else {
            setBookingData({});
            setIsLoading(false);
           }
        } catch (error) {
          console.error(error);
        }
      };
    
      fetchPendingBookingData();
    }, []);

    const filterBookingHandler = (selectedOption) => {
      setSelectedTrip(selectedOption); // Update selected trip
    
      if (!selectedOption) {
        setFilteredBookingData(bookingData); // Reset filter when no trip is selected
        return;
      }
    
      // Filter booking data based on selected trip ID
      const filteredData = bookingData.filter(trip => trip.id === selectedOption.value);
      setFilteredBookingData(filteredData);
    };
    
  return (
    <div>
      <Select
        name="tripName"
        className="react-select w-25 mb-4"
        classNamePrefix="react-select"
        options={tripOption}
        isClearable
        value={selectedTrip}
        placeholder="Select a trip"
        onChange={filterBookingHandler}
      />

      <div>
        {filteredBookingData.length > 0 ? (
               filteredBookingData.map(booking => (
                 <PastCard 
                   key={booking.id}
                   bookingData={booking}
                 />
               ))
             ) : (
               <p>No bookings available.</p>
             )}
      </div>
    </div>
    );
};



export default Past;
