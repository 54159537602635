import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import WizardForm from './components/WizardForm';
import { AuthContext } from '../../../../../context/AuthContext';
import { BookingContext } from '../../../../../context/BookingContext';



const BookingForm = ({
  setmodalShowBooking, mealName,
  resName,
  price,
  id,
  mealOffereds,
  locations,
}) => {
   const { agentId, apiGlobal } = useContext(AuthContext);
   const {
 paxs, setStep1formData, setPaxs, setFormIndex, 
} = useContext(BookingContext);
  
    const handleBookingSubmit = async (value) => {
      console.log(JSON.stringify(value, null, 2));
      const utcBookingDate = value.bookingDate;
      const utcStartDate = value.startDates;
      const utcEndDate = value.endDate;
    
      // Convert to IST
      const BookingDate = new Date(utcBookingDate);
      const startDate = new Date(utcStartDate);
      const endDate = new Date(utcEndDate);

    
      // IST offset is +5:30
     const istOffset = 5.5 * 60 * 60 * 1000;
     const bookingDateIST = new Date(BookingDate.getTime() + istOffset);
     const startDateIST = new Date(startDate.getTime() + istOffset);
     const endDateIST = new Date(endDate.getTime() + istOffset); 
     
     // Format the dates (DD-MM-YYYY)
     const formatDate = dates => `${dates.getFullYear()}-${String(dates.getMonth() + 1)
      .padStart(2, '0')}-${String(dates.getDate()).padStart(2, '0')}`;

      const formattedBookingDate = formatDate(bookingDateIST);
      const formattedStartDate = formatDate(startDateIST);
      const formattedEndDate = formatDate(endDateIST);
 


     const totalamount = value.price * value.totalMeals;
     console.log(totalamount);

     console.log(value.resLocation);

      const formData = new FormData();
      formData.append('agent_id', agentId);
      formData.append('booking_date', formattedBookingDate);
      formData.append('trip_id', value.selectedTrip.value);
      formData.append('location_id', value.resLocation.value);
      formData.append('meal_id', id);
      formData.append('meal_date', formattedBookingDate);
      formData.append('meal_offered', value.timing.value);
      formData.append('day_name', value.dayname);
      formData.append('meal_amount', value.price);
      formData.append('total_meal', value.totalMeals);
      formData.append('total_amount', totalamount);
      formData.append('pax_ids', paxs);

      formData.forEach((values, key) => {
        console.log(`${key}: ${values}`);
      });

     try {
      const url = await fetch(`${apiGlobal}/agent/booking_add.php`, {
         method: 'POST',
         body: formData,
      });
      const response = await url.json();
        if (response.status && response.message === 'Success') {
          toast.success('Meal Booking Successfully...');
          setStep1formData({});
          setPaxs([]);
          setFormIndex(1);
          setmodalShowBooking(false);
        } else {
          toast.error('Error in Meal Booking...');
        }
     } catch (error) {
       console.log(error);
     }
    };


 

  return (
    <Container>
      <Row>
        <Col md={12} />
      </Row>
      <WizardForm
        onSubmit={handleBookingSubmit}
        mealName={mealName}
        resName={resName}
        price={price}
        id={id}
        locations={locations}
        mealOffereds={mealOffereds}
      />
    </Container>
  );
};

BookingForm.propTypes = {
    mealName: PropTypes.string.isRequired,
    resName: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    mealOffereds: PropTypes.string.isRequired,
    locations: PropTypes.shape({
        id: PropTypes.string.isRequired,
        restaurant_name: PropTypes.string.isRequired,
    
      }).isRequired,
  setmodalShowBooking: PropTypes.func.isRequired,
};

export default BookingForm;
