import React, { useState, useContext } from 'react';
import {
   Nav, Tab, Modal,
} from 'react-bootstrap';
import { NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs';
import { FaPlus } from 'react-icons/fa';
import { Button } from '@/shared/components/Button';
import toast from 'react-hot-toast';
import Search from './Search';
import AddAgent from './AddAgent';
import RequestList from './RequestList';
import { AgentContext } from '../../context/AgentContext';
import RejectedRequest from './RejectRequestTab/RejectedRequest';
import { PermissionContext } from '../../context/PermissionContext';
import { ThemeContext } from '../../context/ThemeContext';
import { AuthContext } from '../../context/AuthContext';

const Tabs = () => {
  const [showModal, setShowModal] = useState(false);
  const { setRefresh, setRefreshRequest } = useContext(AgentContext);
  const { permissionsAccess } = useContext(PermissionContext);
  const { currentTheme } = useContext(ThemeContext);
  const { apiGlobal } = useContext(AuthContext);


 
  const handleSubmit = async (formValues) => {
    const formData = new FormData();
    formData.append('agentName', formValues.agentName);
    formData.append('user_email', formValues.email);
    formData.append('uan', formValues.uan);
    formData.append('addressLine1', formValues.addressline1);
    formData.append('addressLine2', formValues.addressline2);
    formData.append('area', formValues.area);
    formData.append('city', formValues.city);
    formData.append('state', formValues.state);
    formData.append('country', formValues.country);
    formData.append('pincode', formValues.pincode);
    formData.append('locationOnMap', formValues.location);
    formData.append('logo', formValues.file);
    formData.append('gst_no', formValues.gst_no);
    formData.append('gst_type', formValues.gst_type);
    formData.append('gst_proof_file', formValues.gst_proof);
    formData.append('address_proof_file', formValues.address_proof);
    formData.append('defaultMarkup', formValues.markup);
    formData.append('taxId', formValues.taxId);
    formData.append('taxCity', formValues.taxCity);

    Array.from(formData.entries()).forEach(([key, value]) => {
      // If the value is a file, we need to log file details, not just the file object
      if (value instanceof File) {
          console.log(key, value.name); // Log file name
      } else {
          console.log(key, value); // Log other values
      }
  });

    try {
      const response = await fetch(`${apiGlobal}/agent_add.php`, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      console.log('Response:', data);

      if (data.status) {
        toast.success('Agent added successfully!');
       
        setShowModal(false); 
        setRefresh(prev => !prev);
        setRefreshRequest(prev => !prev);
      } else {
        toast.error('Something went wrong. Please try again.');
      }
    } catch (error) {
      alert(`An Error Occurred: ${error.message}`);
      console.error('Submit Error:', error);
    }
  };
   
    
  return (
    <>
      {/* Tabs */}
      <Tab.Container defaultActiveKey="1" style={{ marginTop: '500px' }}>
        <TabsWrap>
          {permissionsAccess?.[0]?.master_id === '1'
                  && permissionsAccess?.[0]?.create === 1 && (
                    <Button 
                      variant="outline-primary" 
                      size="sm" 
                      onClick={() => setShowModal(true)}
                    >
                      <span>Add Agent</span><FaPlus />
                    </Button>
                  )}
         
          {/* <Button variant="primary" onClick={() => setShowModal(true)} style={{ marginTop: '20px' }}>
            Add Agent
          </Button> */}
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink eventKey="1">Search <sup><span style={{ color: 'red' }} /></sup></NavLink>
            </NavItem>
            <NavItem>
              <NavLink eventKey="2">Request</NavLink>
            </NavItem>
            <NavItem>
              <NavLink eventKey="3"><span style={{ color: 'red' }}>Rejected Request</span></NavLink>
            </NavItem>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="1">
              <Search />
              
            </Tab.Pane>
            <Tab.Pane eventKey="2">
              <RequestList className="col-md-12" />
            </Tab.Pane>
            <Tab.Pane eventKey="3">
              <RejectedRequest className="col-md-12" />
            </Tab.Pane>
          </Tab.Content>
        </TabsWrap>
      </Tab.Container>

      {/* Modal for Adding Agent */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="xl"
        
      >
        <Modal.Header
          style={{
          backgroundColor: currentTheme === 'Dark' && '#232329',
          color: currentTheme === 'Dark' && '#fff',
        }}
          closeButton
          closeVariant={currentTheme === 'Dark' && 'white'}
        >
          <Modal.Title>Add Agent
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
          backgroundColor: currentTheme === 'Dark' && '#232329',
        }}
        >
          <AddAgent onSubmit={handleSubmit} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Tabs;
