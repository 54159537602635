import React from 'react';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';

const PaymentInfo = ({ paymentData }) => {
    const formatDate = (dateString) => {
        if (!dateString) return ''; // Handle empty values
        const [year, month, day] = dateString.split('-');
        return `${day}-${month}-${year}`;
    };

    // Parse and subtract amounts safely (with number type assumption)
    const safeParseAmount = (amount) => {
      const parsedAmount = parseFloat(amount);
      return Number.isNaN(parsedAmount) ? 0 : parsedAmount;
    };

    return (
      <Table
        responsive
        style={{
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(178, 114, 114, 0.1)',
        }}
      >
        <thead className="bg-primary" style={{ color: 'white', textAlign: 'center' }}>
          <tr>
            <th>#</th>
            <th>Payment Date</th>
            <th>Payment Type</th>
            <th>Payment Amount</th>
            <th>Total Amount</th>
            <th>Balance</th>
            <th>Narration</th>
            <th>Ref No</th>
            <th>Bank Name</th>
            <th>Cheque Date</th>
          </tr>
        </thead>
        <tbody>
          <tr key={paymentData.refNo}>
            <td>{1}</td>
            <td>{formatDate(paymentData.paymentDate)}</td>
            <td>
              {(() => {
                switch (paymentData.paytype) {
                  case '1':
                    return 'Cash';
                  case '2':
                    return 'online';
                  case '3':
                    return 'NEFT/RTGS/IMPS/Cheque';
                  default:
                    return 'Unknown Payment Method';
                }
              })()}
            </td>
            <td>{paymentData.paymentAmount}</td>
            <td>{paymentData.totalAmount}</td>
            <td>
              {safeParseAmount(paymentData.totalAmount) - safeParseAmount(paymentData.paymentAmount)}
            </td>
            <td>{paymentData.narration}</td>
            <td>{paymentData.paytype !== '1' ? paymentData.refNo : 'NA'}</td>
            <td>{paymentData.paytype === '3' ? paymentData.bankName : 'NA'}</td>
            <td>{paymentData.paytype === '3' ? formatDate(paymentData.chequeDate) : 'NA'}</td>
          </tr>
        </tbody>
      </Table>
    );
};

PaymentInfo.propTypes = {
  paymentData: PropTypes.shape({
    paytype: PropTypes.string.isRequired,
    paymentDate: PropTypes.string.isRequired,
    paymentAmount: PropTypes.string.isRequired, // Consider changing this to number if it's always numeric
    totalAmount: PropTypes.string.isRequired, // Same for this
    narration: PropTypes.string.isRequired,
    refNo: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
    chequeDate: PropTypes.string.isRequired,
  }).isRequired,
};

export default PaymentInfo;
