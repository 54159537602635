import React, { useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Col, Modal } from 'react-bootstrap';
import { Button } from '@/shared/components/Button';
import Pagination from 'react-bootstrap/Pagination';
import toast from 'react-hot-toast';
import { AuthContext } from '../../../../../../context/AuthContext';
import ComfirmationModel from '../../../util/ComfirmationModel';
import RejectReasonForm from '../../../../../Agent/RejectReasonForm';
import BookingInfo from './Info/BookingInfo';
import PendingPaymentInfo from './Info/PendingPaymentInfo';

const DataReactTable = () => {
  const [pendingData, setPendingData] = useState([]);
  const [showConfirmModal, setShowConfirmModel] = useState(false);
  const [showRejectModal, setShowRejectModel] = useState(false);
  const [showInfoHandler, setshowInfoHandler] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [BookingId, setBookingId] = useState('');
  const [bookingAmount, setBookingAmount] = useState('');
  const { apiGlobal } = useContext(AuthContext);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle empty values
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const fetchPendingData = async () => {
    try {
      const url = await fetch(`${apiGlobal}account_pending_list.php`);
      const data = await url.json();
      console.log(data);
      if (data.status && data.message === 'Success') {
        setPendingData(data.data);
      } else {
        setPendingData([]);
        console.error('Something Went Wrong While fetching Payment Pending list');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPendingData();
  }, [refresh]);

  const handleBookPaymentApprove = (id, amount) => {
    setShowConfirmModel(true);
    setBookingId(id);
    setBookingAmount(amount);
  };
  const handlebBookPaymentReject = (id) => {
    setShowRejectModel(true);
    setBookingId(id);
  };

  const handleConfirmPayment = async () => {
      try {
        const url = await fetch(`${apiGlobal}account_payment_confirmation.php?isApproved=1&id=${BookingId}`);
        const data = await url.json();
        if (data.status && data.message === 'Success') {
          toast.success('Payment Confirm');
          setRefresh(prev => !prev);
          setBookingId(null);
          setShowConfirmModel(false);
        } else {
          toast.error('Failed to Confirm Payment');
        }
      } catch (error) {
        console.log(error);
      }
  };

  const handleRejectPayment = async (value) => {
    try {
      const url = await fetch(`${apiGlobal}account_payment_confirmation.php?isApproved=2&
        id=${BookingId}&reason=${value.reason}`);
      const response = await url.json();
      if (response.status && response.message === 'Success') {
        toast.success('Reject Sucessfully');
        setRefresh(prev => !prev);
        setShowRejectModel(false);
      } else {
        toast.error('Failed to Reject Payment');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination Logic - Slice data for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = pendingData.slice(indexOfFirstItem, indexOfLastItem);

  // Total pages
  const totalPages = Math.ceil(pendingData.length / itemsPerPage);


  const handleBookingInfo = (id) => {
    setBookingId(id);
    setshowInfoHandler(true);
  };

  return (
    <div>
      <Table
        responsive
        style={{
          backgroundColor: '#f9f9f9',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <thead className="bg-primary" style={{ color: 'white', textAlign: 'center' }}>
          <tr>
            <th>#</th>
            <th>Payment Date</th>
            <th>Staff Type</th>
            <th>Staff Name</th>
            <th>Booking Amount</th>
            <th>Info</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length === 0 ? (
            // Render this message if there is no data
            <tr>
              <td colSpan="7" style={{ textAlign: 'center', padding: '20px' }}>
                <h3>No Pending Data Available</h3>
              </td>
            </tr>
          ) : (
            currentItems.map((booking, index) => (
              <tr
                key={booking.id}
                style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#f2f2f2', textAlign: 'center' }}
              >
                <td>{index + 1}</td>
                <td>{formatDate(booking.payment_date)}</td>
                <td>{booking.staff_type}</td>
                <td>{booking.staff_name}</td>
                <td>{booking.balance}</td>
                <td>
                  <Button 
                    variant="outline-primary" 
                    title="info"
                    onClick={() => handleBookingInfo(booking.booking_id)}
                  >
                    <span className="lnr lnr-eye" />
                  </Button>
                </td>
                <td>
                  <Button
                    variant="outline-success"
                    title="Accept"
                    onClick={() => handleBookPaymentApprove(booking.id, booking.balance)}
                  >
                    Accept
                  </Button>
                  <Button 
                    variant="outline-danger" 
                    title="Reject"
                    onClick={() => handlebBookPaymentReject(booking.id, booking.balance)}
                  >
                    Reject
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {/* Pagination Controls */}
      <div style={{
 position: 'fixed', 
        bottom: '20px', 
        right: '20px', 
        zIndex: 999, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
}}
      >
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>


      {/* Confirmation Modal */}
      <ComfirmationModel
        show={showConfirmModal}
        handleClose={() => setShowConfirmModel(false)}
        message="Are you sure you want to approve this payment?"
        amount={bookingAmount}
        handleApproved={handleConfirmPayment}
        header="Approve Payment"
      />
      <Modal
        show={showRejectModal}
        onHide={() => setShowRejectModel(false)}
        size="md"
      >
        <Modal.Header
    //       style={{
    //   backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
    //   color: currentTheme === 'Dark' ? 'white' : 'black',
    // }}
          closeButton
          // closeVariant={currentTheme === 'Dark' && 'white'}
        >
          <Modal.Title>Add Reject Remark</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RejectReasonForm onSubmit={value => handleRejectPayment(value)} text="Payment" />
        </Modal.Body>
      </Modal>
      <Modal
        show={showInfoHandler}
        onHide={() => setshowInfoHandler(false)}
        centered
        size="xl"
      >
        <Modal.Header
          // style={{
          //       backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
          //       color: currentTheme === 'Dark' ? 'white' : 'black',
          //     }}
          closeButton
          // closeVariant={currentTheme === 'Dark' && 'white'}
        >
          <Modal.Title>Booking & Payment Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PendingPaymentInfo bookingID={BookingId} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DataReactTable;
