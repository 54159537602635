import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@/shared/components/Button';






const PastCard = ({
  bookingData,
 }) => {
  const getMealTiming = (mealOffered) => {
    const timings = {
      1: 'Breakfast',
      2: 'Snacks',
      3: 'Lunch',
      4: 'Dinner',
    };
    return timings[mealOffered] || 'Unknown';
  };
  
  const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle empty values
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };
  
  const getStatusMessage = (status) => {
    if (status === '1') return 'Awaiting approval from restaurant';
    if (status === '2') return 'Payment Pending';
    if (status === '3') return 'Payment Pending';
    if (status === '4') return 'Confirmed';
    return 'Pending Approval';
  };
  
  return (
    <div className="card mb-4 shadow-sm" style={{ maxWidth: '100%', borderColor: 'black' }}>
      <div className="card-body">
        {/* Header Section */}
        <div className="row mb-3">
          <div className="col-md-12">
            <h5 className="fw-bold">{bookingData.name}</h5>
          </div>
        </div>
    
        {/* Trip Info */}
        <div className="row mb-4">
          <div className="col-md-4" style={{ display: 'flex' }}>
            <h6 className="fw-bold">
              Trip: <p>{bookingData.name} - {bookingData.trip_uin}</p>
            </h6>
          </div>
          <div className="col-md-4" style={{ display: 'flex' }}>
            <p className="mb-1">
              <strong>Start Date:</strong> {bookingData.start_date ? formatDate(bookingData.start_date) : ''}
            </p>
            <p style={{ marginLeft: '12px' }}>
              <strong>End Date:</strong> {bookingData.end_date ? formatDate(bookingData.end_date) : ''}
            </p>
          </div>
          <div className="col-md-4 text-end">
            <Button variant="outline-success" size="sm" className="mx-1" title="Edit">
              Email
            </Button>
            <Button variant="outline-warning" size="sm" className="mx-1" title="Edit">
              Edit Trip
            </Button>
            <Button variant="outline-primary" size="sm" className="mx-1" title="Add">
              Add Meal
            </Button>
          </div>
        </div>
    
        {/* Booking Details */}
        {bookingData.booking_details
              && [...bookingData.booking_details]
                .sort((a, b) => (a.status === '0' ? -1 : 1)) // Sort meals with status '0' first
                .map(meal => (
                  <div className="card mb-4 shadow-sm" key={meal.id} style={{ maxWidth: '100%' }}>
                    <div className="card-body">
                      {/* Meal Day Header */}
                      <div className="row mb-2 align-items-center" style={{ display: 'flex' }}>
                        <div className="col-md-8 d-flex align-items-center">
                          <h6 className="me-2">
                            {meal.day_name} - {meal.booking_date ? formatDate(meal.booking_date) : ''}{' '}
                            
                          </h6>
                          {/* <Button variant="outline-success" size="sm" className="mx-1" title="Email">
                            Email
                          </Button> */}
                        </div>
                      </div>
    
                      {/* Meal Details */}
                      <div className="row border p-3 rounded">
                        <div className="col-md-3">
                          <p><strong>{meal.meal_name} / {meal.restaurant_name}</strong></p>
                          <p className="text-muted">Locations: {meal.location}</p>
                        </div>
                        <div className="col-md-3">
                          <p><strong>Timing:</strong> {getMealTiming(meal.meal_offered)}</p>
                          <p><strong>Total Meals:</strong> {meal.total_meal}</p>
                        </div>
                        <div className="col-md-3">
                          <p><strong>Type:</strong> GIT, FIT, Delivery</p>
                          <p><strong>Format:</strong> Thali</p>
                        </div>
                        <div className="col-md-3">
                          <p><strong>Amount:</strong> AED {meal.total_amount}</p>
                          <p>
                            <strong>Status:</strong>{' '}
                            <span 
                              style={{
        color: meal.status === '4' ? 'green' : 'yellow',
      }}
                            >
                              {getStatusMessage(meal.status)}
                            </span>
    
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
      </div>
    </div>
  );
}; 

PastCard.propTypes = {
  bookingData: PropTypes.shape({
    id: PropTypes.string.isRequired, 
    agent_id: PropTypes.string.isRequired, 
    name: PropTypes.string.isRequired, 
    trip_uin: PropTypes.string.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
    booking_details: PropTypes.arrayOf(PropTypes.shape({


    })).isRequired,
  }).isRequired, 
};



export default PastCard;
