
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { Card } from '@/shared/components/Card';
import {
  WizardFormWrap,
  WizardStep,
  WizardSteps,
  WizardWrap,
} from '@/shared/components/form/WizardFormElements';
import toast from 'react-hot-toast';
import WizardFormOne from './WizardFormOne';
import WizardFormTwo from './WizardFormTwo';
import { BookingContext } from '../../../../../../context/BookingContext';

const WizardForm = ({
  onSubmit, mealName, resName, price, id, mealOffereds, locations,
}) => {
  const {
    setStep1formData,
    step1formData,
    formIndex,
    setFormIndex,
    paxs,
    setPaxs,
  } = useContext(BookingContext);
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  // Update context data when props change or on initial render
  useEffect(() => {
    if (id) {
      setStep1formData(prevData => ({
        ...prevData,
        mealName,
        resName,
        price,
        mealOffereds,
        locations,
        resid: id,
      }));
    }
  }, [id, mealName, resName, price, mealOffereds, locations, setStep1formData]);

  const nextPage = (values) => {
    console.log(JSON.stringify(values, null, 2));
    setFormIndex(prevIndex => prevIndex + 1);
    setStep1formData(previousValues => ({
      ...previousValues,
      tripId: values.tripId,
      triptype: values.triptype,
      startDates: values.startDates,
      endDate: values.endDate,
      selectedTrip: values.selectedTrip,

    }));
  };

  const previousPage = () => {
    setFormIndex(prevIndex => prevIndex - 1);
    setPaxs([]);
  };

  return (
    <Row>
      <Col md={12} lg={12}>
        <Card>
          <WizardWrap>
            <WizardSteps>
              <WizardStep active={formIndex === 1}>
                <p>Step 1</p>
              </WizardStep>
              <WizardStep active={formIndex === 2}>
                <p>Step 2</p>
              </WizardStep>
            </WizardSteps>
            <WizardFormWrap>
              {formIndex === 1 && step1formData && Object.keys(step1formData).length > 0 && (
                <WizardFormOne
                  onSubmit={nextPage}
                  date={date}
                  setDate={setDate}
                  initialValues={step1formData}
                />
              )}
              {formIndex === 2 && step1formData && (
                <WizardFormTwo
                  previousPage={previousPage}
                  date={date}
                  onSubmit={onSubmit}
                  initialValues={step1formData}
                />
              )}
            </WizardFormWrap>
          </WizardWrap>
        </Card>
      </Col>
    </Row>
  );
};

WizardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  mealName: PropTypes.string.isRequired,
  resName: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  locations: PropTypes.shape({
      id: PropTypes.string.isRequired,
      restaurant_name: PropTypes.string.isRequired,
  }).isRequired,
  mealOffereds: PropTypes.string.isRequired,
};

export default WizardForm;
