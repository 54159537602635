import React, { useState, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ThemeContext } from '../../../../context/ThemeContext';

const ComfirmationModel = ({ 
  show, 
  handleClose, 
handleApproved, 
  message,
  header,
  amount,
}) => {
   const { currentTheme } = useContext(ThemeContext);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      
    >
      <Modal.Header
        closeButton
        closeVariant={currentTheme === 'Dark' && 'white'}
        style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}>
      
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}>
        {message} of Amount - {amount}
      </Modal.Body>
      <Modal.Footer style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleApproved}>
          Approve
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ComfirmationModel.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleApproved: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  };

export default ComfirmationModel;
