import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal } from 'react-bootstrap';
import { MdDateRange } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import { Button } from '@/shared/components/Button';
import toast from 'react-hot-toast';
import { Form } from 'react-final-form';
import { AgentContext } from '../../context/AgentContext';
import { AuthContext } from '../../context/AuthContext';
import { ThemeContext } from '../../context/ThemeContext';
import AgentInfo from './AgentInfo';
import RejectReasonForm from './RejectReasonForm';

const RequestShort = ({
  index, 
  name, 
  id, 
  date,
  logo,
}) => {
  const { apiGlobal } = useContext(AuthContext);
  const path = `${apiGlobal}images/agent/`;
  const [selectedImage] = useState(path + logo);
  const { setRefresh, setStatusChanged } = useContext(AgentContext);
  const { currentTheme } = useContext(ThemeContext);
  const [showInfoHandler, setshowInfoHandler] = useState(false);
  const [showRejectForm, setshowRejectForm] = useState(false);
  const [agentId, setAgentId] = useState(id);
  const [loading, setLoading] = useState(false);

  

  function formatDate(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  }

  const handleAccept = async (ids) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${apiGlobal}agent_confirmation.php?id=${ids}&isApproved=1`,
        { method: 'GET' },
      );
      const result = await response.json();

      if (result.status && result.message === 'Success') {
        setStatusChanged(true);
        setRefresh(prev => !prev);
        toast.success('Request accepted successfully');
      } else {
        setStatusChanged(false);
        toast.error(result.message || 'Failed to accept request');
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  // const handleReject = async (ids) => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(
  //       `https://navrang.mvd-tech.io/API/agent_confirmation.php?id=${ids}&isApproved=2`,
  //       { method: 'GET' },
  //     );
  //     const result = await response.json();

  //     if (result.status && result.message === 'Success') {
  //       setStatusChanged(true);
  //       toast.success('Request rejected successfully');
  //       setRefresh(prev => !prev);
  //     } else {
  //       setStatusChanged(false);
  //       toast.error(result.message || 'Failed to reject request');
  //     }
  //   } catch (error) {
  //     toast.error(error.message || 'Something went wrong');
  //   } finally {
  //     setLoading(false);
  //   }
  // };


  const handleReject = async (value) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${apiGlobal}agent_confirmation.php?id=${agentId}&isApproved=2&reason=${value.reason}`,
        { method: 'GET' },
      );
      const result = await response.json();

      if (result.status && result.message === 'Success') {
        setStatusChanged(true);
        toast.success('Request rejected successfully');
        setRefresh(prev => !prev);
        setshowRejectForm(false);
      } else {
        setStatusChanged(false);
        toast.error(result.message || 'Failed to reject request');
        console.log('Failed to reject request:', result);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
      console.error('Error rejecting request:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Col
      md={12}
      xl={3}
      lg={6}
      xs={12}
      className="m-4"
      style={{
        
    border: '2px solid #ccc',
    borderRadius: '10px',
    padding: '15px',
    height: '260px',
    width: '240px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    backgroundColor: currentTheme === 'Dark' ? '#232329' : '#f9f9f9',
  }}
      onMouseEnter={(e) => {
    e.currentTarget.style.transform = 'translateY(-5px)';
    e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
  }}
      onMouseLeave={(e) => {
    e.currentTarget.style.transform = 'translateY(0)';
    e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
  }}
    >
      <div>
        <div style={{ textAlign: 'center' }}>
          
          <span style={{ color: '#ff4861', fontWeight: 'bold', fontSize: '1.2rem' }}>
            #{index + 1}
          </span>
          
          <div
            style={{
          margin: '10px auto',
          width: '75px',
          height: '75px',
          borderRadius: '50%',
          overflow: 'hidden',
          border: '2px solid #ff4861',
        }}
          >
            <img
              src={selectedImage}
              alt={name}
              style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
            />
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <h5 
            className="mt-3" 
            style={{ color: currentTheme === 'Dark' ? 'white' : '#333' }}
          >
            <CgProfile style={{ color: currentTheme === 'Dark' ? 'white' : '#6c757d' }} /> {name}
          </h5>
          <h5 style={{ color: currentTheme === 'Dark' ? 'white' : '#555' }}>
            <MdDateRange style={{ color: currentTheme === 'Dark' ? 'white' : '#6c757d' }} /> {formatDate(date)}
          </h5>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            size="sm"
            variant="outline-success"
            onClick={() => handleAccept(id)}
            style={{ marginRight: '10px' }}
          >
            Confirm
          </Button>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => setshowRejectForm(true)}
          >
            Reject
          </Button>
          <Button
            size="sm"
            variant="outline-primary"
            title="View Info"
            onClick={() => setshowInfoHandler(true)}
          >
            <span className="lnr lnr-eye" />
          </Button>
        </div>
      </div>
      <Modal
        show={showInfoHandler}
        onHide={() => setshowInfoHandler(false)}
        centered
        size="xl"
      >
        <Modal.Header
          style={{
          backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
          color: currentTheme === 'Dark' ? 'white' : 'black',
        }}
          closeButton
          closeVariant={currentTheme === 'Dark' && 'white'}
        >
          <Modal.Title>Agent Info</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
          color: currentTheme === 'Dark' ? 'white' : 'black',
        }}
        >
          <AgentInfo agentId={id} />
        </Modal.Body>
      </Modal>
      <Modal
        show={showRejectForm}
        onHide={() => setshowRejectForm(false)}
        size="md"
      
      >
        <Modal.Header
          style={{
          backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
          color: currentTheme === 'Dark' ? 'white' : 'black',
        }}
          closeButton
          closeVariant={currentTheme === 'Dark' && 'white'}
        >
          <Modal.Title>Add Reject Remark</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
          backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
          color: currentTheme === 'Dark' ? 'white' : 'black',
        }}
        >
          <RejectReasonForm onSubmit={handleReject} text="Agent" />
        </Modal.Body>
      </Modal>
    </Col>

  );
};

RequestShort.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default RequestShort;
