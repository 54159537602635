import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
 Table, Button, Form, Row, Col, Container, 
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const PaxTable = ({ paxdata, setPaxDetailsHandler }) => {
  const [itemValue, setItemValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [ageGroupValue, setAgeGroupValue] = useState('');
  const [newPaxData, setNewPaxData] = useState(paxdata);

  useEffect(() => {
    setNewPaxData(paxdata);
    console.log(paxdata);
  }, [paxdata]);

  const handleAddPax = () => {
    if (!itemValue || !emailValue || !phoneValue || !ageGroupValue) {
      toast.error('Some fields are empty!');
      return;
    }

    const newPax = {
      id: new Date().toISOString(),
      pax_id: 0,
      pax_name: itemValue,
      email_id: emailValue,
      mobile_no: phoneValue,
      age_group: ageGroupValue,
    };

    const updatedPaxData = [...newPaxData, newPax];
    setNewPaxData(updatedPaxData);
    setPaxDetailsHandler(updatedPaxData);

    setItemValue('');
    setEmailValue('');
    setPhoneValue('');
    setAgeGroupValue('');
  };

  const handleRemovePax = (id) => {
    const updatedPaxData = newPaxData.filter(pax => pax.pax_id !== id);
    setNewPaxData(updatedPaxData);
    setPaxDetailsHandler(updatedPaxData);
  };

  return (
    <Container className="mt-4">
      {/* <h4 className="mb-3 text-center">Pax Details</h4> */}
      <Table responsive="sm" striped bordered hover>
        <thead className="thead-dark">
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Age Group</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {newPaxData.map((pax, index) => (
            <tr key={pax.pax_id}>
              <td>{index + 1}</td>
              <td>{pax.pax_name}</td>
              <td>{pax.email_id}</td>
              <td>{pax.mobile_no}</td>
              <td>{pax.age_group === '1' ? 'Adult' : 'Child'}</td>
              <td>
                <Button variant="danger" size="sm" onClick={() => handleRemovePax(pax.pax_id)}>
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h4 className="mt-4 text-center">Add More Pax</h4>
      <Form className="p-3 border rounded bg-light">
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={itemValue}
                onChange={e => setItemValue(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                value={emailValue}
                onChange={e => setEmailValue(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Phone No.</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter Phone No."
                value={phoneValue}
                onChange={e => setPhoneValue(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Age Group</Form.Label>
              <Form.Select value={ageGroupValue} onChange={e => setAgeGroupValue(e.target.value)}>
                <option value="">Select Age Group</option>
                <option value="1">Adult</option>
                <option value="2">Child</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <div className="text-center mt-3">
          <Button variant="primary" size="lg" onClick={handleAddPax}>
            Add Pax
          </Button>
        </div>
      </Form>
    </Container>
  );
};

PaxTable.propTypes = {
  paxdata: PropTypes.arrayOf(
    PropTypes.shape({
      pax_id: PropTypes.string.isRequired,
      pax_name: PropTypes.string.isRequired,
      mobile_no: PropTypes.string.isRequired,
      email_id: PropTypes.string.isRequired,
      age_group: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setPaxDetailsHandler: PropTypes.func.isRequired,
};

export default PaxTable;



// code for re-review 
// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import 'bootstrap/dist/css/bootstrap.min.css';

// // Your existing PaxTable component
// const PaxTable = ({ paxdata, setPaxDetailsHandler }) => {
//   const [itemValue, setItemValue] = useState('');
//   const [emailValue, setEmailValue] = useState('');
//   const [phoneValue, setPhoneValue] = useState('');
//   const [ageGroupValue, setAgeGroupValue] = useState('');
//   const [newPaxData, setNewPaxData] = useState(paxdata);

//   const handleAddPax = () => {
//     const newPax = {
//       pax_id: new Date().toISOString(), // Generate a unique ID using timestamp
//       pax_name: itemValue,
//       email_id: emailValue,
//       mobile_no: phoneValue,
//       age_group: ageGroupValue,
//     };

//     const updatedPaxData = [...newPaxData, newPax];
//     setNewPaxData(updatedPaxData);
//     setPaxDetailsHandler(updatedPaxData);

//     // Reset form fields after adding Pax
//     setItemValue('');
//     setEmailValue('');
//     setPhoneValue('');
//     setAgeGroupValue('');
//   };

//   const handleRemovePax = (id) => {
//     // Filter out the Pax entry by its pax_id
//     const updatedPaxData = newPaxData.filter(pax => pax.pax_id !== id);
//     setNewPaxData(updatedPaxData);
//     setPaxDetailsHandler(updatedPaxData);
//   };


//   return (
//     <div className="container mt-5">
//       {/* Table displaying the Pax data */}
//       <table className="table table-striped table-bordered">
//         <thead className="thead-dark">
//           <tr>
//             <th scope="col">#</th>
//             <th scope="col">Name</th>
//             <th scope="col">Email</th>
//             <th scope="col">Phone Number</th>
//             <th scope="col">Age Group</th>
//             <th scope="col">Actions</th> {/* Added column for actions */}
//           </tr>
//         </thead>
//         <tbody>
//           {newPaxData.map((pax, index) => (
//             <tr key={pax.pax_id}>
//               <th scope="row">{index + 1}</th>
//               <td>{pax.pax_name}</td>
//               <td>{pax.email_id}</td>
//               <td>{pax.mobile_no}</td>
//               <td>{pax.age_group === '1' ? 'Adult' : 'Child'}</td>
//               <td>
//                 <button
//                   type="button"
//                   className="btn btn-danger"
//                   onClick={() => handleRemovePax(pax.pax_id)} // Call handleRemovePax with Pax ID
//                 >
//                   Remove
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Form for adding more Pax */}
//       <div className="mt-4">
//         <h4>Add More Pax</h4>
//         <div className="form-section">
//           <div className="form-row">
//             {/* Column for name and email */}
//             <div className="col-md-6">
//               <div className="form-group">
//                 <label htmlFor="paxName">Name</label>
//                 <input
//                   id="paxName"
//                   type="text"
//                   className="form-control"
//                   placeholder="Enter Name"
//                   value={itemValue}
//                   onChange={e => setItemValue(e.target.value)}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="paxEmail">Email</label>
//                 <input
//                   id="paxEmail"
//                   type="email"
//                   className="form-control"
//                   placeholder="Enter Email"
//                   value={emailValue}
//                   onChange={e => setEmailValue(e.target.value)}
//                 />
//               </div>
//             </div>

//             {/* Column for phone number and age group */}
//             <div className="col-md-6">
//               <div className="form-group">
//                 <label htmlFor="paxPhone">Phone No.</label>
//                 <input
//                   id="paxPhone"
//                   type="number"
//                   className="form-control"
//                   placeholder="Enter Phone No."
//                   value={phoneValue}
//                   onChange={e => setPhoneValue(e.target.value)}
//                 />
//               </div>
//               <div className="form-group">
//                 <label htmlFor="paxAgeGroup">Age Group</label>
//                 <select
//                   id="paxAgeGroup"
//                   className="form-control"
//                   value={ageGroupValue}
//                   onChange={e => setAgeGroupValue(e.target.value)}
//                 >
//                   <option value="">Select Age Group</option>
//                   <option value="1">Adult</option>
//                   <option value="2">Child</option>
//                 </select>
//               </div>
//             </div>
//           </div>

//           <button
//             type="button"
//             className="btn btn-primary mt-3"
//             onClick={handleAddPax}
//           >
//             Add Pax
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// PaxTable.propTypes = {
//   paxdata: PropTypes.arrayOf(
//     PropTypes.shape({
//       pax_id: PropTypes.string.isRequired, // Use pax_id as unique identifier
//       pax_name: PropTypes.string.isRequired,
//       mobile_no: PropTypes.string.isRequired,
//       email_id: PropTypes.string.isRequired,
//       age_group: PropTypes.string.isRequired,
//     }),
//   ).isRequired,
//   setPaxDetailsHandler: PropTypes.func.isRequired,
// };

// export default PaxTable;

