
import React, {
  useContext, useState, useEffect,
 } from 'react';
 import PropTypes from 'prop-types';
 import { Field, Form } from 'react-final-form';
 import { Col } from 'react-bootstrap';
 import { Card, CardBody, CardTitleWrap } from '@/shared/components/Card';
 import Select from 'react-select';
 import {
  FormButtonToolbar, FormContainer, FormGroup, FormGroupField, FormGroupLabel, FormHalfContainer, 
 } from '@/shared/components/form/FormElements';
 import DatePicker from 'react-datepicker';
 import renderRadioButtonField from '@/shared/components/form/RadioButton';
 import { Button, ButtonGroup } from '@/shared/components/Button';
import { propTypes } from 'react-bootstrap/esm/Image';
import { AuthContext } from '../../../../../context/AuthContext';
import PaxTable from './PaxTable';
 
 const EditBooking = ({
onSubmit, agentID, bookingID, setPaxDetailsHandler, paxDetailsHandler, 
}) => {
  //  const { setShowAddTripModal } = useContext(BookingContext);
   const { apiGlobal } = useContext(AuthContext);
  //  const { agentId } = useContext(AuthContext);
   const [tripData, setTripData] = useState({});
   const [startDate, setStartDate] = useState(new Date());
   const [endDate, setEndDate] = useState(new Date());
   const [initialValue, setIntialValue] = useState();


  const fetchexistingdata = async () => {
      try {
          const url = await fetch(`${apiGlobal}/agent/trip_data_fetch.php?id=${bookingID}`);
          const response = await url.json();
          console.log(response);
          if (response.status && response.message === 'Success') {
            const tripDatas = response.data[0];

            // Parse dates as Date objects
            const parsedStartDate = new Date(tripDatas.start_date); 
            const parsedEndDate = new Date(tripDatas.end_date);
    
            // // Update state with parsed dates
            setStartDate(parsedStartDate);
            setEndDate(parsedEndDate);
             setIntialValue(prevState => ({
              ...prevState,
              tripId: tripDatas.trip_uin,
              triptype: tripDatas.trip_type,
              startDates: parsedStartDate,
              endDate: parsedEndDate,
              tripName: tripDatas.name,
            }));

            const transformedPaxDetails = response.pax_details.map(pax => ({
              pax_id: pax.id, 
              pax_name: pax.pax_name,
              age_group: pax.age_group,
              mobile_no: pax.mobile_no,
              email_id: pax.email_id,
            }));
          setPaxDetailsHandler(transformedPaxDetails);
          // console.log(transformedPaxDetails);
          } else {
            setTripData({});
          }
        } catch (error) {
          console.log(error);
        }
  };

  useEffect(() => {
      fetchexistingdata();
  }, [bookingID]);


   return (
     <Col md={12} lg={12}>
       <Card>
         <CardBody>
           <CardTitleWrap />
           <Form onSubmit={onSubmit} initialValues={initialValue}>
             {({ handleSubmit, form }) => (
               <FormContainer onSubmit={handleSubmit}>
                 <FormHalfContainer>
                   <FormGroup>
                     <FormGroupField>
                       <FormGroup>
                         <FormGroupLabel>Trip Name</FormGroupLabel>
                         <FormGroupField>
                           <Field
                             name="tripName"
                             component="input"
                             type="text"
                             placeholder="Trip Name"
                           />
                         </FormGroupField>
                       </FormGroup>

                     </FormGroupField>
                   </FormGroup>
 
                   <FormGroup>
                     <FormGroupLabel>Trip ID</FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="tripId"
                         component="input"
                         type="text"
                         placeholder="Trip Id"
                         readOnly
                         initialValue={tripData.trip_uin}
                       />
                     </FormGroupField>
                   </FormGroup>
                   <FormGroup>
                     <FormGroupLabel>
                       Start Date<span style={{ color: 'red' }}>*</span>
                     </FormGroupLabel>
                     <FormGroupField>
                       <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                         <div>
                           <Field
                             name="startDates"
                             render={({ input }) => (
                               <DatePicker
                                 {...input}
                                 selected={startDate} // Ensure this gets updated
                                 onChange={(date) => {
        setStartDate(date);
        input.onChange(date); // Update form state
      }}
                                 dateFormat="dd-MM-yyyy"
                               />
  )}
                           />

                         </div>
                         <div>
                           <FormGroupLabel>
                             End Date<span style={{ color: 'red' }}>*</span>
                           </FormGroupLabel>
                           <Field
                             name="endDate"
                             render={({ input }) => (
                               <DatePicker
                                 {...input}
                                 selected={endDate}
                                 onChange={(date) => {
        setEndDate(date);
        input.onChange(date); // Update form state
      }}
                                 dateFormat="dd-MM-yyyy"
                               />
  )}
                           />

                         </div>
                       </div>
                     </FormGroupField>
                   </FormGroup>
 
                   <FormGroup>
                     <FormGroupLabel>Trip Type</FormGroupLabel>
                     <FormGroupField>
                       <Field
                         name="triptype"
                         component={renderRadioButtonField}
                         label="Group"
                         radioValue="1"
                         initialValue={tripData.trip_type}
                       />
                       <Field
                         name="triptype"
                         component={renderRadioButtonField}
                         label="Family"
                         radioValue="2"
                         initialValue={tripData.trip_type}
                       />
                     </FormGroupField>
                   </FormGroup>
                 </FormHalfContainer>
 
                 <FormHalfContainer>
                   <PaxTable 
                     paxdata={paxDetailsHandler}
                     setPaxDetailsHandler={setPaxDetailsHandler}
                   />
                 </FormHalfContainer>
 
                 <FormButtonToolbar>
                   <Button variant="primary" type="submit" className="next">Update Trip</Button>
                 </FormButtonToolbar>
               </FormContainer>
             )}
           </Form>
         </CardBody>
       </Card>
     </Col>
   );
 };
 
 EditBooking.propTypes = {
   onSubmit: PropTypes.func.isRequired,
   agentID: PropTypes.string.isRequired,
   bookingID: PropTypes.string.isRequired,
   paxDetailsHandler: PropTypes.arrayOf(
       PropTypes.shape({
         // Define the shape of each object inside the array
         // id: PropTypes.number.isRequired,
         // name: PropTypes.string.isRequired,
         // age: PropTypes.number,
         // Add any other fields relevant to the object
       }),
     ).isRequired,
   setPaxDetailsHandler: PropTypes.func.isRequired,
 };
 
 export default EditBooking;
 

   
