import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const BookingContext = createContext();

const BookingContextProvider = ({ children }) => {
    const [showAddTripModal, setShowAddTripModal] = useState(false);
    const [paxs, setPaxs] = useState([]);
    const [step1formData, setStep1formData] = useState({});
    const [formIndex, setFormIndex] = useState(1);


    // this flag is use in booking when add meal
    const [tripDataFlag, setTripDataFlag] = useState({
      tripID: '',
      addMealINBooking: false,
    });

  const value = useMemo(() => ({
    showAddTripModal,
    setShowAddTripModal,
    paxs, 
    setPaxs,
    step1formData, 
    setStep1formData,
    formIndex, 
    setFormIndex,
    tripDataFlag,
    setTripDataFlag,

  }), [showAddTripModal, paxs, step1formData, formIndex, tripDataFlag]);

  return (
    <BookingContext.Provider value={value}>
      {children}
    </BookingContext.Provider>
  );
};

BookingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BookingContextProvider;


// const [showAddTripModal, setShowAddTripModal] = useState(false);
// for Avoiding Propdriling Because modal locate in meal card and their associate button in wizard form two

// const [paxs, setPaxs] = useState([]);
// for store/pass paxs details to entire booking
