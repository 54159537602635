import React, { useContext, useState } from 'react';
import { FormGroupLabel } from '@/shared/components/form/FormElements';
import { Field } from 'react-final-form';
import { Button } from '@/shared/components/Button';
import { IoIosRemoveCircle } from 'react-icons/io';
import Select from 'react-select';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import { BookingContext } from '../../../../../../context/BookingContext';

// Styled-components for styling
const Container = styled.div`
  background-color: #f7f7f7;
  min-height: 47vh;
`;

const PaxDetailsCardWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const FormSection = styled.div`
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d1d1d1;
  max-height: 250px;
  overflow-y: auto;
`;

const TableWrapper = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-top: 20px;
`;

const ReactSelect = styled(Select)`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  font-size: 12px;
  &:hover {
    border-color: #007bff;
  }
`;

const InputField = styled.input`
  width: 100%;
  padding: 8px 10px;
  border-radius: 4px;
  border: 1px solid #d1d1d1;
  font-size: 12px;
  margin-bottom: 10px;
  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const ButtonRemove = styled(Button)`
  color: red;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const ButtonAdd = styled(Button)`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 10px;
  &:hover {
    background-color: #0056b3;
  }
`;

const FormGroupLabelStyled = styled(FormGroupLabel)`
  font-weight: 500;
  color: #495057;
  margin-bottom: 5px;
  font-size: 12px;
`;

const FormRow = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const PaxDetailsCard = () => {
  const [itemValue, setItemValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [typeSelect, setTypeSelect] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const { paxs, setPaxs } = useContext(BookingContext);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone) => {
    const regex = /^\d{10}$/;
    return regex.test(phone);
  };

  const handleAddMeal = () => {
    if (!itemValue.trim() || !typeSelect) {
      alert('Please fill in all fields.');
      return;
    }

    if (!validateEmail(emailValue)) {
      setEmailError('Please enter a valid email address.');
      return;
    } 
      setEmailError('');
    

    if (!validatePhone(phoneValue)) {
      setPhoneError('Please enter a valid 10-digit phone number.');
      return;
    } 
      setPhoneError('');
    

    const pax = {
      item_id: Date.now(),
      pax_name: itemValue.trim(),
      email_id: emailValue.trim(),
      mobile_no: phoneValue.trim(),
      age_group: typeSelect.value,
      age_group_name: typeSelect.label,
    };

    setPaxs(prevPaxs => [...prevPaxs, pax]);

    setItemValue('');
    setEmailValue('');
    setPhoneValue('');
    setTypeSelect(null);
  };

  const handleRemoveMeal = (paxId) => {
    setPaxs(prevPaxs => prevPaxs.filter(pax => pax.item_id !== paxId));
  };

  const handleSelectChange = (selectedOption) => {
    setTypeSelect(selectedOption);
  };

  return (
    <Container>
      <PaxDetailsCardWrapper>
        <FormSection>
          <FormRow>
            <Column>
              <FormGroupLabelStyled>Name</FormGroupLabelStyled>
              <InputField
                type="text"
                placeholder="Enter Name"
                value={itemValue}
                onChange={e => setItemValue(e.target.value)}
              />
              <FormGroupLabelStyled>Email</FormGroupLabelStyled>
              <InputField
                type="email"
                placeholder="Enter Email"
                value={emailValue}
                onChange={(e) => {
                  setEmailValue(e.target.value);
                  if (!validateEmail(e.target.value)) {
                    setEmailError('Please enter a valid email address.');
                  } else {
                    setEmailError('');
                  }
                }}
              />
              {emailError && <span style={{ color: 'red', fontSize: '12px' }}>{emailError}</span>}
            </Column>

            <Column>
              <FormGroupLabelStyled>Phone No.</FormGroupLabelStyled>
              <InputField
                type="number"
                placeholder="Enter Phone No."
                value={phoneValue}
                onChange={(e) => {
                  setPhoneValue(e.target.value);
                  if (!validatePhone(e.target.value)) {
                    setPhoneError('Please enter a valid 10-digit phone number.');
                  } else {
                    setPhoneError('');
                  }
                }}
              />
              {phoneError && <span style={{ color: 'red', fontSize: '12px' }}>{phoneError}</span>}
              <FormGroupLabelStyled>Type</FormGroupLabelStyled>
              <Field
                name="age_group"
                render={({ input }) => (
                  <ReactSelect
                    {...input}
                    name="age_group"
                    options={[
                      { value: '1', label: 'Adult' },
                      { value: '2', label: 'Child' },
                    ]}
                    placeholder="Select Age Group"
                    value={typeSelect}
                    onChange={(selectedOption) => {
                      input.onChange(selectedOption ? selectedOption.value : '');
                      handleSelectChange(selectedOption);
                    }}
                  />
                )}
                required
              />
            </Column>
          </FormRow>
        </FormSection>

        <TableWrapper>
          <Table responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone No.</th>
                <th>Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {paxs.map((pax, index) => (
                <tr key={pax.item_id}>
                  <td>{index + 1}</td>
                  <td>{pax.pax_name}</td>
                  <td>{pax.email_id}</td>
                  <td>{pax.mobile_no}</td>
                  <td>{pax.age_group_name}</td>
                  <td>
                    <ButtonRemove
                      type="button"
                      variant="outline-danger"
                      size="sm"
                      onClick={() => handleRemoveMeal(pax.item_id)}
                    >
                      <IoIosRemoveCircle />
                    </ButtonRemove>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>

        <ButtonAdd onClick={handleAddMeal}>Add Pax</ButtonAdd>
      </PaxDetailsCardWrapper>
    </Container>
  );
};

export default PaxDetailsCard;
