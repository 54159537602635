// import React, { useState, useContext, useEffect } from 'react';
// import {
//    Nav, Tab, Modal,
// } from 'react-bootstrap';
// import { NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs';
// import { FaPlus } from 'react-icons/fa';
// import { Button } from '@/shared/components/Button';
// import toast from 'react-hot-toast';
// import Search from './Search';
// import AddMeal from './AddMeal';
// import { Mealcontext } from '../../../../context/MealContext';
// import MealDetails from './MealDetails';
// import { PermissionContext } from '../../../../context/PermissionContext';
// import { ThemeContext } from '../../../../context/ThemeContext';
// import RequestList from './Request/RequestList';

// const Tabs = () => {
//   const { 
//     setRefresh, 
//     isDetailsClick, 
//     click, 
//     setclick,
//     activeKey, 
//     setActiveKey,
//     meals, 
//     setMeals,
//   } = useContext(Mealcontext);
//   const { permissionsAccess } = useContext(PermissionContext);
//   const { currentTheme } = useContext(ThemeContext);
//   const [showModal, setShowModal] = useState(false);
//   // const [activeKey, setActiveKey] = useState('1');
//   // const [click, setclick] = useState('searchSelect');
//   // isDetailsClick is true
//   useEffect(() => {
//     if (isDetailsClick) {
//       setActiveKey('3');
//       setclick('detailsClick');
//       // console.log(activeKey); 
//     }
//   }, [isDetailsClick]);

//   const AddMeals = async (values) => {
//       // console.log(JSON.stringify(values, null, 2));

//     const utcStartDate = values.startDate;
//     const utcEndDate = values.endDate;

//     // Convert to IST
//     const startDate = new Date(utcStartDate);
//     const endDate = new Date(utcEndDate);

//     // IST offset is +5:30
//    const istOffset = 5.5 * 60 * 60 * 1000;
//    const startDateIST = new Date(startDate.getTime() + istOffset);
//    const endDateIST = new Date(endDate.getTime() + istOffset); 
   
//    // Format the dates (DD-MM-YYYY)
//    const formatDate = date => `${date.getFullYear()}-${String(date.getMonth() + 1)
//     .padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;


// const formattedStartDate = formatDate(startDateIST);
// const formattedEndDate = formatDate(endDateIST);
 

//     // console.log(JSON.stringify(values.restaurantid.value));
//     const formattedValues = {
//         items: meals,
//     };
//   //   // console.log(formattedValues);

//   //   const sdate = new Date(values.startDate);
//   //   const startDate = sdate.toISOString().split('T')[0];
//   //   const edate = new Date(values.endDate);
//   //   const endDate = edate.toISOString().split('T')[0];
      

//     const mealOffered = [];

//     if (values.breakfast) {
//         mealOffered.push('1');
//     }
//     if (values.snaks) {
//         mealOffered.push('2');
//     }
//     if (values.lunch) {
//         mealOffered.push('3');
//     }
//     if (values.dinner) {
//         mealOffered.push('4');
//     }

//     const location = [];

    
 

//     if (!values.restaurantid) {
//       toast.error('Select a restaurant');
//       return;
//     }

//     if (!values.locationsid) {
//       toast.error('Select atleast one restaurant location');
//       return;
//     }

//     if (mealOffered.length === 0) {
//       toast.error('Select atleast one meal offered');
//       return;
//     }
   
//     if (!values.format) {
//       toast.error('Select  meal format');
//       return;
//     }

//     if (!values.veg) {
//       toast.error('Select  meal veg/nonveg Type');
//       return;
//     }

//   if (formattedStartDate === 'NaN-NaN-NaN' || formattedEndDate === 'NaN-NaN-NaN') {
//     toast.error('Select Date');
//     return;
//   }

//     values.locationsid.forEach((locationItem) => {
//       location.push(locationItem.value);
//     });

//     const formData = new FormData();
//     formData.append('name', values.mealName);
//     formData.append('restaurant_id', values.restaurantid);  
//     formData.append('location_ids', location.join(','));  
//     formData.append('price', values.mealPrice); 
//     formData.append('foc', values.foc); 
//     formData.append('foc_on', values.focon); 
//     formData.append('meal_type', values.mealType); 
//     formData.append('discription', values.discription); 
//      formData.append('photo', values.logo); 
//     formData.append('start_date', formattedStartDate); 
//     formData.append('end_date', formattedEndDate); 
//     formData.append('markup_percentage', values.markup);
//     formData.append('cuisine', values.cuisines); 
//     formData.append('defaultMarkup', values.markup); 
//     formData.append('meal_offered', mealOffered.join(',')); 
//     formData.append('meal_format', values.format); 
//     formData.append('veg_non_veg', values.veg); 
//     // formData.append('veg_non_veg', values.veg); 


//     if (Array.isArray(formattedValues.items)) {
//         formData.append('items', JSON.stringify(formattedValues)); 
//     } else {
//         console.error('Items format is invalid');
//     }

//   //   formData.forEach((value, key) => {
//   //     console.log(`${key}: ${value}`);
//   // });


//     try {
//         const response = await fetch('https://navrang.mvd-tech.io/API/meal_add.php', {
//             method: 'POST',
//             body: formData,
//         });
//         if (!response.ok) {
//             console.log(`Network response was not ok: ${response.statusText}`);
//         }
//         const responseData = await response.json();
//         if (responseData.status && responseData.message === 'Data inserted successfully.') {
//             toast.success('Meal Added Successfully...');
//             setRefresh(prev => !prev);
//             setShowModal(false);
//             setMeals([]);
//         } else {
//             toast.error(responseData.message);
//         }
//     } catch (error) {
//         toast.error(error.message);
//     }    
// };


// const hideModalHandler = () => {
//   setShowModal(false);
//   setMeals([]);
// };




 
//   console.log(activeKey);

//   return (
//     <Tab.Container activeKey={activeKey} onSelect={key => setActiveKey(key)}>
//       <TabsWrap>
//         {permissionsAccess?.[2]?.master_id === '3'
//         && permissionsAccess?.[2]?.create === 1 && (
//           <Button 
//             variant="outline-primary" 
//             size="sm" 
//             onClick={() => setShowModal(true)}
//           >
//             <span>Add Meal</span><FaPlus />
//           </Button>
//         )}
        
          
//         {/* <Button variant="primary" onClick={() => setShowModal(true)} style={{ marginTop: '20px' }}>
//             Add Agent
//           </Button> */}
//         <Nav className="nav-tabs">
//           <NavItem>
//             <NavLink eventKey="1" disabled={isDetailsClick}>Search</NavLink>
//           </NavItem>
//           <NavItem>
//             <NavLink eventKey="2" disabled={isDetailsClick}>Request</NavLink>
//           </NavItem> 
//           <NavItem>
//             <NavLink eventKey="3" disabled={!isDetailsClick}>Details</NavLink>
//           </NavItem>
           
            
//         </Nav>
//         <Tab.Content>
//           <Tab.Pane eventKey="1">
//             {click === 'searchSelect' && <Search />}
//           </Tab.Pane>
//           <Tab.Pane eventKey="2">
//             {click === 'detailsClick' && <MealDetails setActiveKey={setActiveKey} />}
//           </Tab.Pane>
//           <Tab.Pane eventKey="3">
//             <RequestList />
//           </Tab.Pane>
//         </Tab.Content>
//       </TabsWrap>
//       {/* Modal for Adding Agent */}
//       <Modal
//         show={showModal}
//         onHide={hideModalHandler}
//         centered
//         size="xl"
//       >
//         <Modal.Header 
//           style={{
//           backgroundColor: currentTheme === 'Dark' && '#232329',
//           color: currentTheme === 'Dark' && '#fff',
//         }}
//           closeButton
//           closeVariant={currentTheme === 'Dark' && 'white'}
//         >
//           <Modal.Title>Add Meal</Modal.Title>
//         </Modal.Header>
//         <Modal.Body
//           style={{
//           backgroundColor: currentTheme === 'Dark' && '#232329',
//         }}
//         >
//           <AddMeal onSubmit={AddMeals} theme={currentTheme} />
//         </Modal.Body>
//       </Modal>
//     </Tab.Container>
//   );
// };

// export default Tabs;


import React, { useState, useContext, useEffect } from 'react';
import { Nav, Tab, Modal } from 'react-bootstrap';
import { NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs';
import { FaPlus } from 'react-icons/fa';
import { Button } from '@/shared/components/Button';
import toast from 'react-hot-toast';
import Search from './Search';
import AddMeal from './AddMeal';
import { Mealcontext } from '../../../../context/MealContext';
import MealDetails from './MealDetails';
import { PermissionContext } from '../../../../context/PermissionContext';
import { ThemeContext } from '../../../../context/ThemeContext';
import RequestList from './Request/RequestList';
import RejectedRequest from './RejectRequestTab/RejectedRequest';

const Tabs = () => {
  const { 
    setRefresh, 
    isDetailsClick, 
    click, 
    setclick,
    activeKey, 
    setActiveKey,
    meals, 
    setMeals,
  } = useContext(Mealcontext);
  const { permissionsAccess } = useContext(PermissionContext);
  const { currentTheme } = useContext(ThemeContext);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isDetailsClick) {
      setActiveKey('3');
      setclick('detailsClick');
    }
  }, [isDetailsClick]);

  const AddMeals = async (values) => {
    const utcStartDate = values.startDate;
    const utcEndDate = values.endDate;

    // Convert to IST
    const startDate = new Date(utcStartDate);
    const endDate = new Date(utcEndDate);

    // IST offset is +5:30
    const istOffset = 5.5 * 60 * 60 * 1000;
    const startDateIST = new Date(startDate.getTime() + istOffset);
    const endDateIST = new Date(endDate.getTime() + istOffset);

    // Format the dates (DD-MM-YYYY)
    const formatDate = date => `${date.getFullYear()}-${String(date.getMonth() + 1)
      .padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

    const formattedStartDate = formatDate(startDateIST);
    const formattedEndDate = formatDate(endDateIST);

    const formattedValues = {
      items: meals,
    };

    const mealOffered = [];
    if (values.breakfast) mealOffered.push('1');
    if (values.snaks) mealOffered.push('2');
    if (values.lunch) mealOffered.push('3');
    if (values.dinner) mealOffered.push('4');

    const location = [];
    if (!values.restaurantid) {
      toast.error('Select a restaurant');
      return;
    }

    if (!values.locationsid) {
      toast.error('Select at least one restaurant location');
      return;
    }

    if (mealOffered.length === 0) {
      toast.error('Select at least one meal offered');
      return;
    }

    if (!values.format) {
      toast.error('Select meal format');
      return;
    }

    if (!values.veg) {
      toast.error('Select meal veg/nonveg type');
      return;
    }

    if (formattedStartDate === 'NaN-NaN-NaN' || formattedEndDate === 'NaN-NaN-NaN') {
      toast.error('Select Date');
      return;
    }

    values.locationsid.forEach((locationItem) => {
      location.push(locationItem.value);
    });

    const formData = new FormData();
    formData.append('name', values.mealName);
    formData.append('restaurant_id', values.restaurantid);  
    formData.append('location_ids', location.join(','));  
    formData.append('price', values.mealPrice); 
    formData.append('foc', values.foc); 
    formData.append('foc_on', values.focon); 
    formData.append('meal_type', values.mealType); 
    formData.append('discription', values.discription); 
    formData.append('photo', values.logo); 
    formData.append('start_date', formattedStartDate); 
    formData.append('end_date', formattedEndDate); 
    formData.append('cuisine', values.cuisines); 
    formData.append('meal_offered', mealOffered.join(',')); 
    formData.append('meal_format', values.format); 
    formData.append('veg_non_veg', values.veg); 

    if (Array.isArray(formattedValues.items)) {
      formData.append('items', JSON.stringify(formattedValues)); 
    } else {
      console.error('Items format is invalid');
    }

  //   formData.forEach((value, key) => {
  //     console.log(`${key}: ${value}`);
  // });

    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/meal_add.php', {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        console.log(response);
      }
      const responseData = await response.json();
      if (responseData.status && responseData.message === 'Data inserted successfully.') {
        toast.success('Meal Added Successfully...');
        setRefresh(prev => !prev);
        setShowModal(false);
        setMeals([]);
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.error(error.message);
    }    
  };

  const hideModalHandler = () => {
    setShowModal(false);
    setMeals([]);
  };

  return (
    <Tab.Container 
      activeKey={activeKey} 
      onSelect={(key) => {
        let newClick;
        if (key === '2') {
          newClick = 'requestSelect';
        } else if (key === '3') {
          newClick = 'detailsClick';
        } else if (key === '4') {
          newClick = 'RejectedRequestClick';
        } else {
          newClick = 'searchSelect';
        }
        setActiveKey(key);
        setclick(newClick);
      }}
    >
      <TabsWrap>
        {permissionsAccess?.[2]?.master_id === '3'
          && permissionsAccess?.[2]?.create === 1 && (
            <Button 
              variant="outline-primary" 
              size="sm" 
              onClick={() => setShowModal(true)}
            >
              <span>Add Meal</span><FaPlus />
            </Button>
          )}
        <Nav className="nav-tabs">
          <NavItem>
            <NavLink eventKey="1" disabled={isDetailsClick}>Search</NavLink>
          </NavItem>
          <NavItem>
            <NavLink eventKey="2" disabled={isDetailsClick}>Request</NavLink>
          </NavItem>
          <NavItem>
            <NavLink eventKey="3" disabled={!isDetailsClick}>Details</NavLink>
          </NavItem>
          <NavItem>
            <NavLink eventKey="4" disabled={isDetailsClick}>
              <span style={{ color: 'red' }}>Rejected Request</span>
            </NavLink>
          </NavItem>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="1">
            {click === 'searchSelect' && <Search />}
          </Tab.Pane>
          <Tab.Pane eventKey="2">
            {click === 'requestSelect' && <RequestList />}
          </Tab.Pane>
          <Tab.Pane eventKey="3">
            {click === 'detailsClick' && <MealDetails setActiveKey={setActiveKey} />}
          </Tab.Pane>
          <Tab.Pane eventKey="4">
            {click === 'RejectedRequestClick' && <RejectedRequest /> }
          </Tab.Pane>
        </Tab.Content>
      </TabsWrap>
      <Modal
        show={showModal}
        onHide={hideModalHandler}
        centered
        size="xl"
      >
        <Modal.Header 
          style={{
            backgroundColor: currentTheme === 'Dark' && '#232329',
            color: currentTheme === 'Dark' && '#fff',
          }}
          closeButton
          closeVariant={currentTheme === 'Dark' && 'white'}
        >
          <Modal.Title>Add Meal</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: currentTheme === 'Dark' && '#232329',
          }}
        >
          <AddMeal onSubmit={AddMeals} theme={currentTheme} />
        </Modal.Body>
      </Modal>
    </Tab.Container>
  );
};

export default Tabs;
