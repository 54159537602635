import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Card, CardBody, CardTitle } from '@/shared/components/Card';
import Collapse from '@/shared/components/Collapse';
import { borderColor } from 'polished';
import BookingInfo from './BookingInfo';
import PaymentInfo from './PaymentInfo';
import { AuthContext } from '../../../../../../../context/AuthContext';

const PendingPaymentInfo = ({ bookingID }) => {
   const [paymentInfo, setPaymentInfo] = useState({});
    const [bookingDetails, setBookingDetails] = useState({});
    const [paxs, setPaxs] = useState({});
    const { apiGlobal } = useContext(AuthContext);




  const fetchBookingData = async () => {  
    try {
        const url = await fetch(`${apiGlobal}booking_payment_data.php?booking_id=${bookingID}`);
        const data = await url.json();
        console.log(data);
        if (data.status && data.message === 'Success') {
            setPaymentInfo({
                paytype: data.data[0].payment_type || 'NA',
                paymentDate: data.data[0].payment_date || 'NA',
                paymentAmount: data.data[0].in_amount || 'NA',
                totalAmount: data.data[0].booking_details[0].total_amount || 'NA',
                refNo: data.data[0].ref_no || 'NA',
                narration: data.data[0].narration || 'NA',
                bankName: data.data[0].bank || 'NA',
                chequeDate: data.data[0].ref_date || 'NA',
              });
              setBookingDetails({
                mealDate: data.data[0].booking_details[0].meal_date || 'NA',
        meal_offered: data.data[0].booking_details[0].meal_offered || 'NA',
        meal_name: data.data[0].booking_details[0].meal_name || 'NA',
        meal_type: data.data[0].booking_details[0].meal_type || 'NA',
        day_name: data.data[0].booking_details[0].day_name || 'NA',
        meal_amount: data.data[0].booking_details[0].meal_amount || 'NA',
        total_meal: data.data[0].booking_details[0].total_meal || 'NA',
        total_amount: data.data[0].booking_details[0].total_amount || 'NA',
        restaurant_name: data.data[0].booking_details[0].restaurant_name || 'NA',
        location: data.data[0].booking_details[0].location || 'NA',
        trip_name: data.data[0].booking_details[0].trip_name || 'NA',
              });
        setPaxs(data.data[0].booking_details[0].pax_details);
        }
    } catch (e) {
        console.error(e);
    }
  };

  useEffect(() => {
    fetchBookingData();
  }, [bookingID]);




  return (
    <Col md={12}>
      <Card>
        <CardBody>
          
          {/* Basic Details Section */}
          <Collapse title="Payment Details" className="with-shadow">
            <PaymentInfo paymentData={paymentInfo} />
          </Collapse>

          {/* GST Details Section */}
          <Collapse title="Booking Details" className="with-shadow">
            <BookingInfo bookingData={bookingDetails} paxs={paxs} />
          </Collapse>
        </CardBody>
      </Card>
    </Col>
  );
};

PendingPaymentInfo.propTypes = {
    bookingID: PropTypes.string.isRequired,
};

export default PendingPaymentInfo;
