// import React, { useState, useContext, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
// import Badge from '@/shared/components/Badge';
// import { Col } from 'react-bootstrap';
// import {
//   Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
// } from '@/shared/components/Card';
// import { Button, ButtonToolbar } from '@/shared/components/Button';
// import {
//     DropdownMenu, Dropdown, DropdownItem, DropdownDivider, DropdownToggle,
//   } from '@/shared/components/Dropdown';
//   import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
// import { Table } from '@/shared/components/TableElements';
// import { AuthContext } from '../../../../../context/AuthContext';
// import { GlobalVarContext } from '../../../../../context/GlobalVarContext';



// const ResponsiveTable = () => {
//     const { apiGlobal } = useContext(GlobalVarContext);
//     const { userid } = useContext(AuthContext);
//   const [userData, setuserdata] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [status, setStatus] = useState('1');

//   const fetchUserData = async (id) => {
//    try {
//        const url = await fetch(`${apiGlobal}/restaurant/user_restaurant_data.php?restaurant_id=${id}`);
//        const data = await url.json();
//       if (data.status && data.message === 'Success') {
//         setuserdata(data.data);
//         setIsLoading(false);
//       } else {
//         setuserdata([]);
//       }
//    } catch (error) {
//       console.error(error.message);
//    }
//   };

//   useEffect(() => {
//     fetchUserData(userid);
//   }, [userid]);

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }
 
//   const statusHandler = (statuscode) => {
//    if (statuscode === '1') {
//     setStatus('0');
//    } 
//    if (statuscode === '0') {
//     setStatus('1');
//    }
//   };

//   return (
//     <Col md={12} lg={12}>
//       <Card>
//         <CardBody>
//           <CardTitleWrap>
//             <CardSubhead>All Users{' '}<span className="red-text">BitWize-Restaurant</span></CardSubhead>
//           </CardTitleWrap>
//           <Table responsive bordered>
//             <thead style={{ marginTop: '20px' }}>
//               <tr>
//                 <th>Sr no.</th>
//                 <th>Name</th>
//                 <th>Username</th>
//                 <th>Status</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>

//               {userData.map((user, index) => (
//                 <tr key={user.id}>
//                   <td>{index + 1}</td>
//                   <td>{user.name}</td>
//                   <td>{user.username}</td>
//                   <td>
//                     <Dropdown className="btn-group" dir="ltr">
//                       <Button variant="outline-primary" size="sm">
//                         {status === '1' ? 'Approved' : 'Not Approved'}
//                       </Button>
//                       <DropdownToggle variant="outline-primary" size="sm">
//                         <ChevronDownIcon />
//                       </DropdownToggle>
//                       <DropdownMenu>
//                         <Button 
//                           variant="outline-primary" 
//                           size="sm"
//                           onClick={statusHandler(status, user.id)}
//                         >
//                           {status === '1' ? 'Not Approved' : 'Approved'}
//                         </Button>
//                       </DropdownMenu>
//                     </Dropdown>

//                   </td>
//                 </tr>
//                     ))}
              
            
//             </tbody>
//           </Table>
//         </CardBody>
//       </Card>
//     </Col>
//   );
// };

import React, { useState, useContext, useEffect } from 'react';
import { Col, FormControl, Modal } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap, CardSubhead,
} from '@/shared/components/Card';
import { Button, ButtonGroup } from '@/shared/components/Button';
import {
  DropdownMenu, Dropdown, DropdownItem, DropdownToggle,
} from '@/shared/components/Dropdown';
import toast from 'react-hot-toast';
import { Table } from '@/shared/components/TableElements';
import AddUser from './AddUsers';
import EditUser from './EditUser';
import { AuthContext } from '../../../../../context/AuthContext';
import { ThemeContext } from '../../../../../context/ThemeContext';
import DeleteModal from './DeleteModal';

const ResponsiveTable = () => {
  const { userid, apiGlobal } = useContext(AuthContext);
  const { currentTheme } = useContext(ThemeContext);

  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [deleteModalShow, setdeleteModalShow] = useState(false);
  const [holdIDforDelete, setHoldIDforDelete] = useState('');
  const [userDataForEdit, setUserDataForEdit] = useState({});

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${apiGlobal}/user_admin_data.php?user_id=${userid}`);
      const data = await response.json();
      if (data.status && data.message === 'Success') {
        console.log(data.data);
        setUserData(data.data);
        setFilteredData(data.data);
      } else {
        setUserData([]);
        setFilteredData([]);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [userid]);

  useEffect(() => {
    const query = searchQuery.toLowerCase();
    setFilteredData(
      userData.filter(user => user.name.toLowerCase().includes(query) 
      || user.username.toLowerCase().includes(query)),
    );
  }, [searchQuery, userData]);

  const handleStatusToggle = async (userId, currentStatus) => {
    try {
      const newStatus = currentStatus === '1' ? '0' : '1';
      const response = await fetch(
        `${apiGlobal}/user_admin_status.php?id=
        ${userId}&restaurant_id=${userid}&status=${newStatus}`,
      );
      const data = await response.json();
      if (data.status) {
        toast.success('Status Changed.');
        fetchUserData();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error toggling status:', error);
    }
  };

  const handleAddUser = async (user) => {
    console.log(JSON.stringify(user, null, 2));
    try {
      const formData = new FormData();
      Object.entries(user).forEach(([key, value]) => formData.append(key, value));

      const response = await fetch(`${apiGlobal}/user_admin_add.php`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (data.status && data.message === 'Success') {
        toast.success('User added successfully');
        setShowModal(false);
        fetchUserData();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  const handleEditUser = (id, name, username, designation, password, mobile1, mobile2) => {
    setShowModalEdit(true);
    setUserDataForEdit({
      id, name, username, designation, password, mobile1, mobile2,
    });
  };

  const editUserHandler = async (values) => {
    console.log(JSON.stringify(values, null, 2));

    try {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => formData.append(key, value));
      const url = await fetch(`${apiGlobal}/user_admin_edit.php`, {
        method: 'POST',
        body: formData,
      });
      const data = await url.json();
      if (data.status && data.message === 'Record updated successfully.') {
        toast.success('User updated successfully.');
        setShowModalEdit(false);
        fetchUserData();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error updating user:', error);
    } 
  };


  const handleDeleteModal = (id) => {
    setdeleteModalShow(true);
    setHoldIDforDelete(id);
  };

  const handleDeleteUser = async () => {
    try {
      const url = await fetch(`${apiGlobal}/user_admin_delete.php?id=${holdIDforDelete}`);
      const data = await url.json();
      if (data.status && data.message === 'Data deleted successfully') {
        toast.success('User deleted successfully');
        setdeleteModalShow(false);
        setHoldIDforDelete('');
        fetchUserData();
    } else {
      toast.error(data.message);
    }
  } catch (error) {
    console.error('Error deleting user:', error);
  }
};

  const renderTableRows = () => (
    filteredData.map((user, index) => (
      <tr
        key={user.id}
        style={{
        backgroundColor: user.status === '1' ? '#D4F1F4' : '#FCE4EC', // Light cyan for active, soft pink for inactive
        color: user.status === '1' ? '#0F4C5C' : '#880E4F', // Darker shades for contrast
        fontWeight: 'bold',
        transition: 'background-color 0.3s ease-in-out',
      }}
      >
        <td>{index + 1}</td>
        <td>{user.name}</td>
        <td>{user.username}</td>
        <td>
          {user.status === '1' ? (
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              <i className="lnr lnr-checkmark-circle" /> Approved
            </span>
          ) : (
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              <i className="lnr lnr-cross-circle" /> Not Approved
            </span>
          )}
        </td>
        <td>
          {user.id !== '1' && (
            <Dropdown dir="ltr">
              <DropdownToggle variant="outline-primary" size="sm">
                Actions
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleStatusToggle(user.id, user.status)}>
                  {user.status === '1' ? 'Mark as Not Approved' : 'Mark as Approved'}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </td>
        <td>
          <Button
            variant="success"
            title="Edit"
            onClick={() => handleEditUser(
              user.id,
              user.name,
              user.username,
              user.designation,
              user.password,
              user.mobile1,
              user.mobile2,
              )}
          >
            <span className="lnr lnr-pencil" />
          </Button>
          {user.id !== '1' && (
            <Button 
              variant="danger" 
              title="Delete"
              onClick={() => handleDeleteModal(user.id)}
            >
              <span className="lnr lnr-trash" />
            </Button>
          )}
        </td>
      </tr>
    ))
  );

  if (isLoading) return <div>Loading...</div>;

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <CardSubhead>
              <Button
                style={{ float: 'right' }}
                variant="primary"
                size="sm"
                onClick={() => setShowModal(true)}
              >
                Add User
              </Button>
            </CardSubhead>
            <FormControl
              type="text"
              placeholder="Search by name or username"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              style={{
 width: '30%',
marginTop: '10px',
backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
                color: currentTheme === 'Dark' ? 'white' : 'black', 
}}
         
            />
          </CardTitleWrap>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>Sr no.</th>
                <th>Name</th>
                <th>Username</th>
                <th>Status</th>
                <th>Action</th>
                <th>Edit/Delete</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </Table>
        </CardBody>

        {/* Add User Modal */}
        <Modal show={showModal} onHide={() => setShowModal(false)} centered size="md">
          <Modal.Header
            style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}
            closeButton
            closeVariant={currentTheme === 'Dark' && 'white'}
          >
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}
          >
            <AddUser onSubmit={handleAddUser} />
          </Modal.Body>
        </Modal>
          
        {/* Edit User Modal */}
        <Modal show={showModalEdit} onHide={() => setShowModalEdit(false)} centered size="md">
          <Modal.Header
            style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}
            closeButton
            closeVariant={currentTheme === 'Dark' && 'white'}
          >
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}
          >
            <EditUser userDetails={userDataForEdit} onSubmit={editUserHandler} />
          </Modal.Body>
        </Modal>

        {/* Delete User Modal */}
        <DeleteModal
          show={deleteModalShow}
          handleClose={() => setdeleteModalShow(false)}
          handleDelete={handleDeleteUser}
          message="Are you sure you want to delete this User?"
        />
      </Card>
    </Col>
  );
};

export default ResponsiveTable;
