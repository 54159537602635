import React, { useState, useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { 
  FaLocationDot, 
 } from 'react-icons/fa6'; 
import { Button } from '@/shared/components/Button';
import AddEditMeal from '../AddEditMeal';
import { Mealcontext } from '../../../../../context/MealContext';
import { ThemeContext } from '../../../../../context/ThemeContext';





const RejectedRequestCard = ({
  id,
  mealName,
  resName,
  remark,
  photo,
  setRefresh,
}) => {
     const [showEditModel, setShowEditModel] = useState(false);
     const [MealId, setMealId] = useState(id);
     const [restaurantSelectoption, setRestaurantSelectOption] = useState(null);
     const { meals, setMeals } = useContext(Mealcontext);
     const { currentTheme } = useContext(ThemeContext);


  const hideEditModal = () => {
    setShowEditModel(false);
    setMeals([]);
  };

  //     const handleEditResDetails = async (values) => {
  //       const formData = new FormData();
  //       formData.append('id', resId);
  //   formData.append('user_name', values.user_name);
  //   formData.append('user_phone', values.user_phone);
  //   formData.append('user_email', values.user_email);
  //   formData.append('uin', values.uin);
  //   formData.append('acc_name', values.acc_name);
  //   formData.append('acc_country', values.acc_country);
  //   formData.append('account_no', values.account_no);
  //   formData.append('acc_city', values.acc_city);
  //   formData.append('branch', values.branch);
  //   formData.append('ifsc_code', values.ifsc_code);
  //   formData.append('bank', values.bank);
  //   formData.append('photo', values.file);
  //   formData.append('type', '1');

  //   try {
  //     const url = await fetch('https://navrang.mvd-tech.io/API/restaurant_edit.php', {
  //      method: 'POST',
  //      body: formData,
  //     });
  //      const response = await url.json();
  //      if (response.status && response.message === 'Data updated successfully.') {
  //          toast.success('Data updated successfully.');
  //          setRefresh(prev => !prev);
  //          setShowEditModel(false);
  //      } else {
  //          toast.error(response.message);
  //      }
  // } catch (error) {
  //     toast.error(error.message);
  // }
  //     };

  const EditMealData = async (values) => {
       // console.log(selectedid);
       const formattedValues = {
        items: meals,
    };
    const utcStartDate = values.start_date;
    const utcEndDate = values.end_date;
  
    // Convert to IST
    const startDate = new Date(utcStartDate);
    const endDate = new Date(utcEndDate);
  
    // IST offset is +5:30
   const istOffset = 5.5 * 60 * 60 * 1000;
   const startDateIST = new Date(startDate.getTime() + istOffset);
   const endDateIST = new Date(endDate.getTime() + istOffset); 
   
   // Format the dates (DD-MM-YYYY)
   const formatDate = dates => `${dates.getFullYear()}-${String(dates.getMonth() + 1)
    .padStart(2, '0')}-${String(dates.getDate()).padStart(2, '0')}`;
  
  
  const formattedStartDate = formatDate(startDateIST);
  const formattedEndDate = formatDate(endDateIST);
  
      const mealOffered = [];
  
      if (values.breakfast) {
          mealOffered.push('1');
      }
      if (values.snacks) {
          mealOffered.push('2');
      }
      if (values.lunch) {
          mealOffered.push('3');
      }
      if (values.dinner) {
          mealOffered.push('4');
      }
  
      const location = [];
      
  
      if (values.locationsid && Array.isArray(values.locationsid)) {
        values.locationsid.forEach((locationItem) => {
          if (locationItem?.value) {
            location.push(locationItem.value); 
          }
        });
      } else if (values.locations && Array.isArray(values.locations)) {
        values.locations.forEach((locationItem) => {
          if (locationItem?.location_id) {
            location.push(locationItem.location_id); 
          }
        });
      }
  
      if (location.length === 0) {
        toast.error('location not selected....');
        return;
      }
  
  
      if (formattedStartDate === 'NaN-NaN-NaN' || formattedEndDate === 'NaN-NaN-NaN') {
        toast.error('Select Date');
        return;
      }
  
      const formData = new FormData();
      formData.append('id', values.id);
      formData.append('name', values.name); 
      formData.append('restaurant_id', restaurantSelectoption.value);
      formData.append('location_ids', location.join(','));  
      formData.append('price', values.price); 
      formData.append('foc', values.foc); 
      formData.append('foc_on', values.foc_on); 
      formData.append('meal_type', values.meal_type); 
      formData.append('discription', values.description); 
       formData.append('photo', values.logo); 
      formData.append('markup_percentage', values.markup_percentage);
      formData.append('cuisine', values.cuisines); 
      formData.append('start_date', formattedStartDate); 
      formData.append('end_date', formattedEndDate); 
      formData.append('defaultMarkup', values.markup_percentage); 
      formData.append('meal_offered', mealOffered.join(',')); 
      formData.append('meal_format', values.format); 
      formData.append('veg_non_veg', values.veg); 
      formData.append('type', 1); 
      
      if (Array.isArray(formattedValues.items)) {
        formData.append('items', JSON.stringify(formattedValues)); 
    } else {
        console.error('Items format is invalid');
    }
  
      //   formData.forEach((value, key) => {
      //     console.log(`${key}: ${value}`);
      // });
  
      try {
        const response = await fetch('https://navrang.mvd-tech.io/API/meal_edit.php', {
            method: 'POST',
            body: formData,
        });
        if (!response.ok) {
            console.log(`Network response was not ok: ${response.statusText}`);
        }
        const responseData = await response.json();
        if (responseData.status && responseData.message === 'Data updated successfully.') {
            toast.success('Meal Edit Successfully...');
            setRefresh(prev => !prev);
            setShowEditModel(false);
            setMeals([]);
        } else {
            toast.error(responseData.message);
        }
    } catch (error) {
        toast.error(error.message);
    }     
  };

  
  

  return (
    <div 
      className="card mb-3 position-relative" 
      style={{
        maxWidth: '100%',
        border: '2px solid',
        borderRadius: '12px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
        overflow: 'hidden',
        transition: 'transform 0.3s ease',
          backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
          color: currentTheme === 'Dark' ? 'white' : 'black',
        }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(1.02)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1)';
      }}
      
    >

      <div className="row g-0">
        <div className="col-md-12">
          <div className="remark mt-2">
            <i>
              Remark : <span style={{ color: 'red' }}>{remark}</span>
            </i> 
          </div>
          <hr />
        </div>
        <div className="col-md-4 col-12">
          <div className="card-body">
            <h3 className="card-title">
              Meal Name : {mealName}

            </h3>
            <p className="card-text"><FaLocationDot />
              Restaurant Name : {resName}
            </p>
          </div>
        </div>
               
      </div>
      <Button 
        variant="outline-danger"
        title="Edit Info"
        className="w-[12%] align-self-end"
        size="sm"
        style={{
          marginRight: '10px',
        }}
        onClick={() => setShowEditModel(true)}
        
      >
        Edit Meal 
      </Button>    

      
      <Modal show={showEditModel} onHide={hideEditModal} size="xl">
        <Modal.Header 
          style={{
         backgroundColor: currentTheme === 'Dark' && '#232329',
         color: currentTheme === 'Dark' && '#fff',
       }}
          closeButton
          closeVariant={currentTheme === 'Dark' && 'white'}
          
        >
          
          <Modal.Title>Edit Meal</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
          backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
          color: currentTheme === 'Dark' ? 'white' : 'black',
        }}
        >
          <AddEditMeal 
            onSubmit={EditMealData} 
            id={id} 
            setRestaurantSelectOption={setRestaurantSelectOption}
          />
        </Modal.Body>
      </Modal>

      {/* <Modal show={showModalcontact} onHide={() => setShowModalcontact(false)} size="lg">
        <Modal.Header
          style={{
          backgroundColor: theme === 'Dark' && '#232329',
          color: theme === 'Dark' && '#fff',
        }}
          closeButton
          closeVariant={theme === 'Dark' && 'white'}
        >
          <Modal.Title>Additional Information
            <Button variant="outline-primary" className="mx-4" onClick={onPasswordBtn}>Change Password</Button>
            
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
          backgroundColor: theme === 'Dark' && '#232329',
        }}
        >
          <AddContactInfo
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onEditContactSubmit}
          />
        </Modal.Body>
      </Modal> */}

      {/* <Modal show={showModalpassword} onHide={handleClosepassword} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangedPassword
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={onAddPasswordHander}
          />
        </Modal.Body>
      </Modal> */}

    </div>
  );
}; 

RejectedRequestCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  mealName: PropTypes.string.isRequired,
    
    remark: PropTypes.string.isRequired,
    resName: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired,
    setRefresh: PropTypes.func.isRequired,
    

};



export default RejectedRequestCard;
