// import React, { useState, useContext } from 'react';
// import PropTypes from 'prop-types'; // Import PropTypes
// import { useTranslation } from 'react-i18next';
// import { Col } from 'react-bootstrap';
// import { MdDateRange } from 'react-icons/md';
// import ProgressBar from '@/shared/components/ProgressBar';
// import { Card } from '@/shared/components/Card';
// import { CgProfile } from 'react-icons/cg';
// import { Button } from '@/shared/components/Button';
// import toast from 'react-hot-toast';
// import {
//   MobileAppWidgetLine,
//   MobileAppWidgetStat,
//   MobileAppWidgetTitle,
//   MobileWidgetIconDown,
// } from '../../../../Dashboards/App/MobileWidgetDashboardElements';
// import {
//   DashboardWidgetCard,
// } from '../../../../Dashboards/BasicDashboardComponents';
// import { AgentContext } from '../../../../../context/AgentContext';


// const RequestShort = ({
//   index, 
//   name, 
//   id, 
//   date,
//   logo,
// }) => {
//   const { t } = useTranslation('common');
//   const path = 'https://navrang.mvd-tech.io/API/images/meal/';
//   const [selectedImage, setSelectedImage] = useState(path + logo);
//   const { setRefresh, setStatusChanged } = useContext(AgentContext);

//   const extractDate = new Date(date);
// const formattedDate = `${(`0${ extractDate.getDate()}`)
//   .slice(-2)}-${(`0${ extractDate.getMonth() + 1}`).slice(-2)}-${extractDate.getFullYear()}`;

//   const handleAccept = async (ids) => {
//     try {
//       const response = await fetch(
//         `https://navrang.mvd-tech.io/API/agent_confirmation.php?id=${ids}&isApproved=1`,
//         { method: 'GET' },
//       );
//       const result = await response.json();

//       if (result.status && result.message === 'Success') {
//         setStatusChanged(true);
//         setRefresh(prev => !prev);
//       } else {
//         setStatusChanged(false);
//       }
//     } catch (error) {
//        toast.error('something went wrong');
//     }
//   };
//   const handleReject = async (ids) => {
//     try {
//       const response = await fetch(
//         `https://navrang.mvd-tech.io/API/agent_confirmation.php?id=${ids}&isApproved=2`,
//         { method: 'GET' },
//       );
//       const result = await response.json();
//       if (result.status && result.message === 'Success') {
//         setStatusChanged(true);
//       } else {
//         setStatusChanged(false);
//         toast.error(result.message);
//       }
//     } catch (error) {
//       toast.error(error.message);
//     }
//   };
//   return (
//     <Col 
//       md={12} 
//       xl={3} 
//       lg={6} 
//       xs={12} 
//       className="m-4" 
//       style={{
//         border: '2px solid darkgrey', 
//         borderRadius: '8px', 
//         padding: '5px',
//         height: '250px',
//         width: '237px',
//       }}
//     >
//       <Card>
//         <DashboardWidgetCard>
//           <div>
//             <MobileAppWidgetLine>
//               <MobileAppWidgetStat color="#ff4861">#{index + 1}</MobileAppWidgetStat>
//               <img
//                 src={selectedImage}
//                 alt={name}
//                 style={{
//                   borderRadius: '50%',
//                   width: '70px',
//                   height: '70px',
//                   objectFit: 'cover',
//                 }}
//               />
//             </MobileAppWidgetLine>
//             <MobileAppWidgetTitle>
//               <h5 className="mt-2"><CgProfile />{' '}{name}</h5>
//               <h5 className="mt-2"><MdDateRange />{' '}{formattedDate}</h5>
//             </MobileAppWidgetTitle>
//             <div className="d-flex mt-4">
//               <Button
//                 size="sm"
//                 variant="outline-success"
//                 onClick={() => handleAccept(id)}
//               >
//                 Confirm
//               </Button>
//               <Button
//                 size="sm"
//                 className="mx-2"
//                 variant="outline-danger"
//                 onClick={() => handleReject(id)}
//               >
//                 Reject
//               </Button>
//               <Button
//                 size="sm"
//                 variant="outline-primary"
//                 title="View Info"
//               >
//                 <span className="lnr lnr-eye" />
//               </Button>
//             </div>
//           </div>
//         </DashboardWidgetCard>
//       </Card>
//     </Col>
//   );
// };

// // PropTypes validation
// RequestShort.propTypes = {
//   index: PropTypes.number.isRequired, // index should be a number and is required
//   name: PropTypes.string.isRequired, // name should be a string and is required
//   id: PropTypes.number.isRequired, // id should be a number and is required
//   date: PropTypes.string.isRequired,
//   logo: PropTypes.string.isRequired, // date should be a string and is required
// };

// export default RequestShort;



import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { Col, Modal } from 'react-bootstrap';
import { MdDateRange } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import { Button } from '@/shared/components/Button';
import toast from 'react-hot-toast';
import { RestaurantContext } from '../../../../../context/RestaurantContext';
import AddMarkupPercentageForm from '../AddMarkupPercentageForm';
import RejectReasonForm from '../../../../Agent/RejectReasonForm';
import { Mealcontext } from '../../../../../context/MealContext';
import { ThemeContext } from '../../../../../context/ThemeContext';

const RequestShort = ({
  index, 
  name, 
  id, 
  date,
  logo,
}) => {
  const path = 'https://navrang.mvd-tech.io/API/images/meal/';
  const [selectedImage, setSelectedImage] = useState(path + logo);
  const { setRefresh } = useContext(Mealcontext);
  const { currentTheme } = useContext(ThemeContext);

  const [showInfoHandler, setshowInfoHandler] = useState(false);
  const [showRejectForm, setshowRejectForm] = useState(false);
  const [showMarkupmodel, setShowmarkupModel] = useState(false);

  function formatDate(dateString) {
    const [datePart] = dateString.split(' '); // Split the date and time
    const [year, month, day] = datePart.split('-');
    return `${day}-${month}-${year}`;
  }
  
  // Accept action
  const handleAccept = async (value, ids) => {
    try {
      const response = await fetch(
        `https://navrang.mvd-tech.io/API/meal_confirmation.php?id=${ids}&isApproved=1
        &markup_percentage=${value.markuppercentage}`, 
        { method: 'GET' },
      );
      const result = await response.json();
      if (result.status && result.message === 'Success') {
        toast.success('Restaurant confirmed!');
        setRefresh(prev => !prev);
        setShowmarkupModel(false);
      } else {
        toast.error(result.message || 'Failed to confirm');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  // Reject action
  const handleReject = async (value, ids) => {
    try {
      const response = await fetch(
        `https://navrang.mvd-tech.io/API/meal_confirmation.php?id=${ids}&isApproved=2&reason=${value.reason}`, 
        { method: 'GET' },
      );
      const result = await response.json();

      if (result.status && result.message === 'Success') {
        toast.success('Restaurant rejected!');
        setRefresh(prev => !prev);
        setshowRejectForm(false);
      } else {
        toast.error(result.message || 'Failed to reject');
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  return (
      
    
    <Col
      md={12}
      xl={3}
      lg={6}
      xs={12}
      className="m-4"
      style={{
    border: '2px solid #ccc',
    borderRadius: '10px',
    padding: '15px',
    height: '260px',
    width: '240px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',

          
  }}
      onMouseEnter={(e) => {
    e.currentTarget.style.transform = 'translateY(-5px)';
    e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)';
  }}
      onMouseLeave={(e) => {
    e.currentTarget.style.transform = 'translateY(0)';
    e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
  }}
    >
      <div>
        <div style={{ textAlign: 'center' }}>
          <span
            style={{
          color: '#ff4861',
          fontWeight: 'bold',
          fontSize: '1.2rem',
        }}
          >
            #{index + 1}
          </span>
          <div
            style={{
          margin: '10px auto',
          width: '75px',
          height: '75px',
          borderRadius: '50%',
          overflow: 'hidden',
          border: '2px solid #ff4861',
        }}
          >
            <img
              src={selectedImage}
              alt={name}
              style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
            />
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <h5 
            className="mt-3" 
            style={{ color: currentTheme === 'Dark' ? 'white' : '#333' }}
          >
            <CgProfile style={{ color: currentTheme === 'Dark' ? 'white' : '#6c757d' }} /> {name}
          </h5>
          <h5 style={{ color: currentTheme === 'Dark' ? 'white' : '#555' }}>
            <MdDateRange style={{ color: currentTheme === 'Dark' ? 'white' : '#6c757d' }} /> {formatDate(date)}
          </h5>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <Button
            size="sm"
            variant="outline-success"
            onClick={() => setShowmarkupModel(true)}
            style={{
          marginRight: '10px',
        }}
          >
            Confirm
          </Button>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => setshowRejectForm(true)}
          >
            Reject
          </Button>
          {/* <Button
            size="sm"
            variant="outline-primary"
            title="View Info"
            onClick={() => setshowInfoHandler(true)}
          >
            <span className="lnr lnr-eye" />
          </Button> */}
        </div>
      </div>

      <Modal
        show={showInfoHandler}
        onHide={() => setshowInfoHandler(false)}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Restaurant Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <RestaurantInfo resID={id} /> */}
          {/* <AgentInfo agentId={id} /> */}
        </Modal.Body>
      </Modal>
      <Modal
        show={showRejectForm}
        onHide={() => setshowRejectForm(false)}
        size="md"
      >
        <Modal.Header
          style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}
          closeButton
          closeVariant={currentTheme === 'Dark' && 'white'}
        >
          <Modal.Title>Add Reject Remark</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}
        >
          <RejectReasonForm onSubmit={value => handleReject(value, id)} text="Meal" />
        </Modal.Body>
      </Modal>
      <Modal
        show={showMarkupmodel}
        onHide={() => setShowmarkupModel(false)}
        size="md"
      >
        <Modal.Header
          style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}
          closeButton
          closeVariant={currentTheme === 'Dark' && 'white'}
        >
          <Modal.Title>Add Meal Markup Percentage</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
      backgroundColor: currentTheme === 'Dark' ? '#232329' : 'white',
      color: currentTheme === 'Dark' ? 'white' : 'black',
    }}
        >
          <AddMarkupPercentageForm onSubmit={value => handleAccept(value, id)} />
        </Modal.Body>
      </Modal>
    </Col>


  );
};

// PropTypes validation
RequestShort.propTypes = {
  index: PropTypes.number.isRequired, 
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired, 
  date: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

export default RequestShort;







