import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody, CardTitleWrap,
} from '@/shared/components/Card';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormHalfContainer,
} from '@/shared/components/form/FormElements';
import { Button } from '@/shared/components/Button';
import PaxDetailsCard from './PaxDetailsCard';


const AddTrip = () => {
  console.log('hello');
  const handleoNSubmit = () => {
    console.log('form submitted');
  };
  return (

    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <CardTitleWrap>
            <p><span style={{ color: 'red' }}>*</span> Mark Fields are required.</p>
          </CardTitleWrap>
          <Form onSubmit={handleoNSubmit}>
            {({ handleSubmit }) => (
              <FormContainer onSubmit={handleSubmit}>
                <FormHalfContainer>
                  <FormGroup>
                    <FormGroupLabel>Trip Name</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="tripname"
                        component="input"
                        type="text"
                        placeholder="Trip Name"
                      />
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>
                      Start Date<span style={{ color: 'red' }}>*</span>
                    </FormGroupLabel>
                    <FormGroupField>
                      <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                        <div>
                          <Field
                            name="startDate"
                            render={({ input }) => (
                              <DatePicker
                                {...input}
                                selected={input.value || null}
                                onChange={date => input.onChange(date)}
                                dateFormat="dd-MM-yyyy"
                              />
            )}
                          />
                        </div>
                        <div>
                          <FormGroupLabel>
                            End Date<span style={{ color: 'red' }}>*</span>
                          </FormGroupLabel>
                          <Field
                            name="endDate"
                            render={({ input }) => (
                              <DatePicker
                                {...input}
                                selected={input.value || null}
                                onChange={date => input.onChange(date)}
                                dateFormat="dd-MM-yyyy"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>Trip Type</FormGroupLabel>
                    <FormGroupField>
                      <Field
                        name="triptype"
                        component={renderRadioButtonField}
                        label="Group"
                        radioValue="1"
                      />
                      <Field
                        name="triptype"
                        component={renderRadioButtonField}
                        label="Family"
                        radioValue="2"
                      />
                    </FormGroupField>
                  </FormGroup>
                </FormHalfContainer>
                <FormHalfContainer>
                  <h4>
                    Pax Details
                  </h4>
                  <PaxDetailsCard />
                </FormHalfContainer>
            
                <FormButtonToolbar>
                  <Button variant="primary" type="submit">Add Trip</Button>
                </FormButtonToolbar>
              </FormContainer>
        )}
          </Form>
        </CardBody>
      </Card>
    </Col>
      
    );
};

// AddTrip.propTypes = {
//   onSubmit: PropTypes.func.isRequired,
// };

export default React.memo(AddTrip);
