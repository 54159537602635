import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Col, 
  Container, 
  Row, 
  Form,
} from 'react-bootstrap';
import { Button } from '@/shared/components/Button';
import {
   Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import { Table } from '@/shared/components/TableElements';
import { Mealcontext } from '../../../../../context/MealContext';
import RequestShort from './RequestShort';

const RequestList = () => {
  const [request, setRequest] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [errors, setError] = useState(null); 
  const { refresh } = useContext(Mealcontext);

  const fetchData = async () => {
    try {
      const response = await fetch('https://navrang.mvd-tech.io/API/meal_pending_list.php');
      const data = await response.json();
      if (data.status && data.message === 'Success') {
        setRequest(data.data);
        console.log(data.data);
      } else {
        setRequest([]);
      }
    } catch (error) {
      setError('Failed to load data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  // Loading and error states rendering
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (errors) {
    return <div>{errors}</div>;
  }

  return (
    <Container className="d-flex flex-wrap ">
      <Row>
        <Col md={12} />
      </Row>      
      <Row>
        {request.length > 0 ? (
  request.map((req, index) => (
    <RequestShort 
      key={req.id} 
      index={index} 
      date={req.created_date_time} 
      name={req.name} 
      id={req.id} 
      logo={req.photo}             
    />
  ))
) : (
  <p>No requests available</p>
)}
      </Row>
    </Container>
  );
};


export default RequestList;
