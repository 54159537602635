import React, { useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { Button } from '@/shared/components/Button';
import { AuthContext } from '../../../../../../context/AuthContext';

const DataReactTable = () => {
  const [approvedData, setApprovedData] = useState([]);
  const { apiGlobal } = useContext(AuthContext);

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 7;

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Handle empty values
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const fetchPendingData = async () => {
    try {
      const url = await fetch(`${apiGlobal}account_data.php`);
      const data = await url.json();
      if (data.status && data.message === 'Success') {
        setApprovedData(data.data);
      } else {
        setApprovedData([]);
        console.error('Something Went Wrong While fetching Payment Pending list');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPendingData();
  }, []);


    // Handle page change
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    // Pagination Logic - Slice data for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = approvedData.slice(indexOfFirstItem, indexOfLastItem);
  
    // Total pages
    const totalPages = Math.ceil(approvedData.length / itemsPerPage);

  return (
    <div>
      <Table
        responsive
        style={{
          backgroundColor: '#f9f9f9',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <thead className="bg-success" style={{ color: 'white', textAlign: 'center' }}>
          <tr>
            <th>#</th>
            <th>Payment Date</th>
            <th>Staff Type</th>
            <th>Staff Name</th>
            <th>Booking Amount</th>
            {/* <th>Info</th> */}
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {approvedData.length === 0 ? (
            // Render this row if there is no data
            <tr>
              <td colSpan="7" style={{ textAlign: 'center', padding: '20px' }}>
                <h3>No Approved  Data Available</h3>
              </td>
            </tr>
          ) : (
            approvedData.map((booking, index) => (
              <tr
                key={booking.id}
                style={{ backgroundColor: index % 2 === 0 ? '#ffffff' : '#f2f2f2', textAlign: 'center' }}
              >
                <td>{index + 1}</td>
                <td>{formatDate(booking.payment_date)}</td>
                <td>{booking.staff_type}</td>
                <td>{booking.staff_name}</td>
                <td>{booking.balance}</td>
                {/* <td>
                  <Button variant="outline-primary" title="info">
                    <span className="lnr lnr-eye" />
                  </Button>
                </td> */}
                <td>
                  <span style={{
                    color: 'green',
                  }}
                  >
                    {booking.status === '1' && 'Approved'}
                  </span>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      {/* Pagination Controls */}
      <div style={{
 position: 'fixed', 
        bottom: '20px', 
        right: '20px', 
        zIndex: 999, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
}}
      >
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default DataReactTable;
